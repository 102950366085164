import React, { useEffect, useState } from 'react'
import { Button, DateField, FormField } from '../../../components'
import YesNoButton from '../../../components/YesNoButton'
import DropdownField from '../../../components/DropdownField'
import { uploadData, config } from '../../../lib/appwrite'
import { useNavigate } from 'react-router-dom'

const BusinessQuestionaire = () => {
  const navigate = useNavigate()
  const [additional_services, setAdditional_Services] = useState([])
  const [tempAddService, setTempAddService] = useState('')

  const [formData, setFormData] = useState({
    company_name: '',
    website: '',
    pc1_first_name: '',
    pc1_middle_name: '',
    pc1_last_name: '',
    pc1_personal_email: '',
    pc1_work_email: '',
    pc1_other_email: '',
    pc1_mobile_phone: '',
    pc1_work_phone: '',
    pc1_fax: '',
    pc2_first_name: '',
    pc2_middle_name: '',
    pc2_last_name: '',
    pc2_personal_email: '',
    pc2_work_email: '',
    pc2_other_email: '',
    pc2_mobile_phone: '',
    pc2_work_phone: '',
    pc2_fax: '',
    street_address: '',
    city: '',
    state: '',
    zipcode: '',
    billing_address_same: '',
    billing_street_address: '',
    billing_city: '',
    billing_state: '',
    billing_zipcode: '',
    legal_structure: '',
    state_incorporated: '',
    date_incorporated: '',
    accounting_basis: '',
    industry: '',
    filed_all_applicable_states: '',
    use_quickbooks: '',
    employees: 0,
    additional_services: [],
    contract_labor: '',
    income_range: '',
    checking_accounts: 0,
    outstanding_litigations: '',
    outstanding_liens: '',
    tax_letters: '',
    firm_goal: '',
    heard_about_from: ''
  })
  const [formDataErrors, setFormDataErrors] = useState({
    company_name: false,
    pc1_first_name: false,
    pc1_last_name: false,
    pc1_personal_email: false,
    pc1_mobile_phone: false,
    street_address: false,
    city: false,
    state: false,
    zipcode: false,
    billing_address_same: false,
    billing_street_address: false,
    billing_city: false,
    billing_state: false,
    billing_zipcode: false,
    legal_structure: false,
    state_incorporated: false,
    date_incorporated: false,
    industry: false,
    filed_all_applicable_states: false,
    use_quickbooks: false,
    employees: false,
    additional_services: false,
    income_range: false,
    outstanding_litigations: false,
    outstanding_liens: false,
    tax_letters: false,
    heard_about_from: false
  })

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      additional_services
    }));
  }, [additional_services]);

  const addService = (service) => {
    if (service === '') { return }

    // Check if service is already present in additional_services
    const index = additional_services.indexOf(service);

    // If service is present, remove it
    if (index !== -1) {
      const updatedServices = [...additional_services];
      updatedServices.splice(index, 1);
      setAdditional_Services(updatedServices);
    } else {
      // If service is not present, add it
      setAdditional_Services([...additional_services, service]);
    }

    setTempAddService('')
  };

  const submit = async () => {
    if (formData.billing_address_same === 'same') {
      // Use a functional update to ensure the state is updated correctly
      setFormData(prevFormData => ({
        ...prevFormData,
        billing_street_address: prevFormData.street_address,
        billing_city: prevFormData.city,
        billing_state: prevFormData.state,
        billing_zipcode: prevFormData.zipcode
      }));
    }

    // Use a callback to run the validation checks after state updates
    setFormData(prevFormData => {
      const newFormDataErrors = { ...formDataErrors };

      newFormDataErrors.company_name = prevFormData.company_name === '';
      newFormDataErrors.pc1_first_name = prevFormData.pc1_first_name === '';
      newFormDataErrors.pc1_last_name = prevFormData.pc1_last_name === '';
      newFormDataErrors.pc1_personal_email = prevFormData.pc1_personal_email === '';
      newFormDataErrors.pc1_mobile_phone = prevFormData.pc1_mobile_phone === '';
      newFormDataErrors.street_address = prevFormData.street_address === '';
      newFormDataErrors.city = prevFormData.city === '';
      newFormDataErrors.state = prevFormData.state === '';
      newFormDataErrors.zipcode = prevFormData.zipcode === '';
      newFormDataErrors.billing_address_same = prevFormData.billing_address_same === '';
      newFormDataErrors.billing_street_address = prevFormData.billing_street_address === '';
      newFormDataErrors.billing_city = prevFormData.billing_city === '';
      newFormDataErrors.billing_state = prevFormData.billing_state === '';
      newFormDataErrors.billing_zipcode = prevFormData.billing_zipcode === '';
      newFormDataErrors.legal_structure = prevFormData.legal_structure === '';
      newFormDataErrors.state_incorporated = prevFormData.state_incorporated === '';
      newFormDataErrors.date_incorporated = prevFormData.date_incorporated === '';
      newFormDataErrors.accounting_basis = prevFormData.accounting_basis === '';
      newFormDataErrors.industry = prevFormData.industry === '';
      newFormDataErrors.filed_all_applicable_states = prevFormData.filed_all_applicable_states === '';
      newFormDataErrors.use_quickbooks = prevFormData.use_quickbooks === '';
      newFormDataErrors.employees = prevFormData.employees === '';
      newFormDataErrors.income_range = prevFormData.income_range === '';
      newFormDataErrors.outstanding_litigations = prevFormData.outstanding_litigations === '';
      newFormDataErrors.outstanding_liens = prevFormData.outstanding_liens === '';
      newFormDataErrors.tax_letters = prevFormData.tax_letters === '';
      newFormDataErrors.heard_about_from = prevFormData.heard_about_from === '';

      setFormDataErrors(newFormDataErrors);

      // Check if any errors exist
      if (Object.values(newFormDataErrors).some(error => error)) {
        return; // Return early if any error is true
      }

      const data = {
        data: JSON.stringify(formData)
      }
      uploadData(data, config.businessQuestionaireCollectionId)

      navigate('/')

      return prevFormData
    });
  };



  return (
    <div className='px-[15%] p-[60px] space-y-4'>
      <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Questionaire</h1>

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Contact Information</h2>

      <FormField
        title="Company Name"
        value={formData.company_name}
        handleChangeText={(e) => setFormData({ ...formData, company_name: e })}
        placeholder=""
        error={formDataErrors.company_name}
        required={true}
      />

      <FormField
        title="Website"
        value={formData.website}
        handleChangeText={(e) => setFormData({ ...formData, website: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Primary Contact 1</h2>

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.pc1_first_name}
          handleChangeText={(e) => setFormData({ ...formData, pc1_first_name: e })}
          placeholder=""
          error={formDataErrors.pc1_first_name}
          required={true}
        />
        <FormField
          title="Middle Name"
          value={formData.pc1_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, pc1_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.pc1_last_name}
        handleChangeText={(e) => setFormData({ ...formData, pc1_last_name: e })}
        placeholder=""
        error={formDataErrors.pc1_last_name}
        required={true}
      />

      <FormField
        title="Personal Email"
        value={formData.pc1_personal_email}
        handleChangeText={(e) => setFormData({ ...formData, pc1_personal_email: e })}
        placeholder=""
        error={formDataErrors.pc1_personal_email}
        required={true}
      />

      <FormField
        title="Work Email"
        value={formData.pc1_work_email}
        handleChangeText={(e) => setFormData({ ...formData, pc1_work_email: e })}
        placeholder=""
      />

      <FormField
        title="Other Email"
        value={formData.pc1_other_email}
        handleChangeText={(e) => setFormData({ ...formData, pc1_other_email: e })}
        placeholder=""
      />

      <FormField
        title="Mobile Phone"
        value={formData.pc1_mobile_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc1_mobile_phone: e })}
        placeholder=""
        error={formDataErrors.pc1_mobile_phone}
        required={true}
      />

      <FormField
        title="Work Phone / Ext"
        value={formData.pc1_work_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc1_work_phone: e })}
        placeholder=""
      />

      <FormField
        title="Fax"
        value={formData.pc1_fax}
        handleChangeText={(e) => setFormData({ ...formData, pc1_fax: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Primary Contact 2</h2>

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.pc2_first_name}
          handleChangeText={(e) => setFormData({ ...formData, pc2_first_name: e })}
          placeholder=""
        />
        <FormField
          title="Middle Name"
          value={formData.pc2_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, pc2_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.pc2_last_name}
        handleChangeText={(e) => setFormData({ ...formData, pc2_last_name: e })}
        placeholder=""
      />

      <FormField
        title="Personal Email"
        value={formData.pc2_personal_email}
        handleChangeText={(e) => setFormData({ ...formData, pc2_personal_email: e })}
        placeholder=""
      />

      <FormField
        title="Work Email"
        value={formData.pc2_work_email}
        handleChangeText={(e) => setFormData({ ...formData, pc2_work_email: e })}
        placeholder=""
      />

      <FormField
        title="Other Email"
        value={formData.pc2_other_email}
        handleChangeText={(e) => setFormData({ ...formData, pc2_other_email: e })}
        placeholder=""
      />

      <FormField
        title="Mobile Phone"
        value={formData.pc2_mobile_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc2_mobile_phone: e })}
        placeholder=""
      />

      <FormField
        title="Work Phone / Ext"
        value={formData.pc2_work_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc2_work_phone: e })}
        placeholder=""
      />

      <FormField
        title="Fax"
        value={formData.pc2_fax}
        handleChangeText={(e) => setFormData({ ...formData, pc2_fax: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Address</h2>

      <FormField
        title="Address"
        value={formData.street_address}
        handleChangeText={(e) => setFormData({ ...formData, street_address: e })}
        placeholder=""
        error={formDataErrors.street_address}
        required={true}
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.city}
          handleChangeText={(e) => setFormData({ ...formData, city: e })}
          placeholder=""
          error={formDataErrors.city}
          required={true}
        />
        <FormField
          title="State"
          value={formData.state}
          handleChangeText={(e) => setFormData({ ...formData, state: e })}
          placeholder=""
          error={formDataErrors.state}
          required={true}
        />
      </div>

      <FormField
        title="Zip"
        value={formData.zipcode}
        handleChangeText={(e) => setFormData({ ...formData, zipcode: e })}
        placeholder=""
        error={formDataErrors.zipcode}
        required={true}
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        handleChangeText={(e) => setFormData({ ...formData, billing_address_same: e })}
        error={formDataErrors.billing_address_same}
        required={true}
      />
      {formData.billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, billing_street_address: e })}
            placeholder=""
            error={formDataErrors.billing_street_address}
            required={true}
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.billing_city}
              handleChangeText={(e) => setFormData({ ...formData, billing_city: e })}
              placeholder=""
              error={formDataErrors.billing_city}
              required={true}
            />

            <FormField
              title="State"
              value={formData.billing_state}
              handleChangeText={(e) => setFormData({ ...formData, billing_state: e })}
              placeholder=""
              error={formDataErrors.billing_state}
              required={true}
            />
          </div>
          <FormField
            title="Zip"
            value={formData.billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, billing_zipcode: e })}
            placeholder=""
            error={formDataErrors.billing_zipcode}
            required={true}
          />
        </div>
      )}

      <DropdownField
        title="Legal Structure"
        selected={formData.legal_structure}
        setSelected={(e) => setFormData({ ...formData, legal_structure: e })}
        options={[
          "Sole proprietor/Single Member LLC",
          "Partnership/Partnership LLC",
          "Multi-Member LLC",
          "Professional LLC",
          "S. Corporation",
          "C. Corporation",
          "Non-Profit Organization"
        ]}
        handleChangeText={(e) => setFormData({ ...formData, legal_structure: e })}
        error={formDataErrors.legal_structure}
        required={true}
      />

      <FormField
        title="State Incorporated"
        value={formData.state_incorporated}
        handleChangeText={(e) => setFormData({ ...formData, state_incorporated: e })}
        placeholder=""
        error={formDataErrors.state_incorporated}
        required={true}
      />

      <DateField
        title="Date Incorporated"
        value={formData.date_incorporated}
        handleChangeText={(e) => setFormData({ ...formData, date_incorporated: e })}
        placeholder=""
        error={formDataErrors.date_incorporated}
        required={true}
      />

      <DropdownField
        title="Accounting Basis"
        selected={formData.accounting_basis}
        setSelected={(e) => setFormData({ ...formData, accounting_basis: e })}
        options={[
          "Cash",
          "Accrual",
          "Other"
        ]}
        handleChangeText={(e) => setFormData({ ...formData, accounting_basis: e })}
        error={formDataErrors.accounting_basis}
        required={true}
      />

      <DropdownField
        title="Industry"
        selected={formData.industry}
        setSelected={(e) => setFormData({ ...formData, industry: e })}
        options={[
          "Agriculture & Forestry/Wildlife Extermination/Pest Control",
          "Business & Information Consultant",
          "Construction/Utilities/Contracting",
          "Education",
          "Finance & Insurance",
          "Food & Hospitality",
          "Gaming",
          "Health Services",
          "Motor Vehicle Automotive Part Sales",
          "Natural Resources/Environmental Conservation Organizations",
          "Personal Services",
          "Real Estate & Housing",
          "Safety/Security & Legal",
          "Transportation",
          "Other"
        ]}
        handleChangeText={(e) => setFormData({ ...formData, industry: e })}
        error={formDataErrors.industry}
        required={true}
      />

      <YesNoButton
        title="Has your company filed all income tax returns to the IRS and all applicable states (past 3yrs)?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, filed_all_applicable_states: e })}
        error={formDataErrors.filed_all_applicable_states}
        required={true}
      />

      <YesNoButton
        title="Do you currently use Quickbooks to record financial transactions for your business?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, use_quickbooks: e })}
        error={formDataErrors.use_quickbooks}
        required={true}
      />

      <FormField
        title="How many employees do you have?"
        value={formData.employees}
        handleChangeText={(e) => setFormData({ ...formData, employees: e })}
        placeholder=""
        type="number"
        error={formDataErrors.employees}
        required={true}
      />

      <YesNoButton
        title="Do you use contract labor?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, contract_labor: e })}
      />

      <div>
        <p className="text-base dark:text-gray-100 font-medium">Do you need any business services?</p>
        <div className='flex space-x-2'>
          {additional_services.map((service, index) => (
            <div key={index} className='' onClick={() => addService(service)}>
              <Button
                bgColor="rgba(107,114,128,0.1)"
                text={service}
                borderRadius="[10px]"
                size="md"
              />
            </div>
          ))}
        </div>
        <DropdownField
          title=""
          selected={tempAddService}
          setSelected={setTempAddService}
          options={[
            "Accounting and Bookkeeping Services",
            "Review and Compilation Report",
            "Organization and Formation",
            "Loan Document Preparation",
            "Advisory Services",
            "Investment and Development Strategic Planning",
            "Income Tax Planning, Preparation and Case Resolution"
          ]}
          handleChangeText={(e) => addService(e)}
          error={formDataErrors.income_range}
        />
      </div>

      <DropdownField
        title="Income Range"
        selected={formData.income_range}
        setSelected={(e) => setFormData({ ...formData, income_range: e })}
        options={[
          "$0 - $99k",
          "$100k - $249k",
          "$250k - $499k",
          "$500k - $749k",
          "$750k - $999k",
          "$1M+"
        ]}
        handleChangeText={(e) => setFormData({ ...formData, income_range: e })}
        error={formDataErrors.income_range}
        required={true}
      />

      <FormField
        title="How many checking accounts?"
        value={formData.checking_accounts}
        handleChangeText={(e) => setFormData({ ...formData, checking_accounts: e })}
        placeholder=""
      />

      <YesNoButton
        title="Are there any outstanding litigations against you or your company?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, outstanding_litigations: e })}
        error={formDataErrors.outstanding_litigations}
        required={true}
      />

      <YesNoButton
        title="Are there any outstanding tax liens or liabilities you or your company?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, outstanding_liens: e })}
        error={formDataErrors.outstanding_liens}
        required={true}
      />

      <YesNoButton
        title="ave you received any tax letters within the last 90 days?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, tax_letters: e })}
        error={formDataErrors.tax_letters}
        required={true}
      />

      <FormField
        title="Briefly describe what you would like our firm to accomplish for you:"
        value={formData.firm_goal}
        handleChangeText={(e) => setFormData({ ...formData, firm_goal: e })}
        placeholder=""
      />

      <DropdownField
        title="How did you hear about our firm?"
        selected={formData.heard_about_from}
        setSelected={(e) => setFormData({ ...formData, heard_about_from: e })}
        options={["Search Engine", "Social Media", "Referral", "Other"]}
        handleChangeText={(e) => setFormData({ ...formData, heard_about_from: e })}
        error={formDataErrors.heard_about_from}
        required={true}
      />

      <div className='flex justify-center items-center'>
        <div onClick={() => submit()}>
          <Button
            bgColor="#F89E34"
            text="Submit"
            borderRadius="[10px]"
            size="md"
          />
        </div>
      </div>

    </div>
  )
}

export default BusinessQuestionaire