import { Double } from '@syncfusion/ej2-react-charts';
import { uniqueID } from '@syncfusion/ej2/base';
import { Dictionary } from '@syncfusion/ej2/pdf-export';
import { Account, Avatars, Client, Databases, ID, Query, Storage } from 'appwrite';

const client = new Client();

export const config = {
  endpoint: 'https://database.streamlineautomations.io/v1',
  projectId: '6648f986001cb56b8db0',
  databaseId: '6648fbfd001ed46014b9',
  userCollectionId: '6648fc09000934219d3d',
  businessQuestionaireCollectionId: '664929d2001ba6fc608c',
  businessIntakeCollectionId: '664a1d620015f7c3552c',
  personalQuestionaireCollectionId: '664929ad0000b58a2ff4',
  personalIntakeCollectionId: '664a1dd1001f6629dfd0',
  permissionsCollectionId: '66638aae0006b4d75a67',
}

client
  .setEndpoint(config.endpoint)
  .setProject(config.projectId)

const account = new Account(client);
const avatars = new Avatars(client);
const databases = new Databases(client);

// Basic Functions 
export const getAllDocuments = async (collectionId, limit, filters = []) => {
  try {
    const databaseId = config.databaseId;
    let offset = 0;
    let allDocuments = [];

    // Construct filter queries
    const filterQueries = filters.map(filter => {
      const [attribute, value] = Object.entries(filter)[0];
      return Query.equal(attribute, value);
    });

    const firstPage = await databases.listDocuments(databaseId, collectionId, [
      Query.limit(5000),
      Query.offset(offset),
      ...filterQueries,
    ]);

    allDocuments = allDocuments.concat(firstPage.documents)

    const lastId = firstPage.documents[firstPage.documents.length - 1].$id

    const secondPage = await databases.listDocuments(databaseId, collectionId, [
      Query.limit(5000),
      Query.cursorAfter(lastId),
      ...filterQueries,
    ]);

    allDocuments = allDocuments.concat(secondPage.documents)

    return allDocuments;
  } catch (error) {
    throw new Error(error);
  }
};

export const getDocument = async (collectionId, documentId) => {
  try {
    const response = await databases.getDocument(config.databaseId, collectionId, documentId);
    return response;
  } catch (error) {
    throw new Error(`Failed to retrieve document: ${error.message}`);
  }
};

export async function uploadData(data, collectionId) {
  try {
    // Call the createDocument method to create a new document
    const response = await databases.createDocument(
      config.databaseId,
      collectionId,
      ID.unique(),
      data
    );

    // Output the newly created document ID
    return response
  } catch (error) {
    return error
  }
}

export const updateDocument = async (id, data, collectionId) => {
  try {
    const response = await databases.updateDocument(config.databaseId, collectionId, id, data)
    return null
  } catch (error) {
    return error
  }
}

export const deleteDocument = async (documentId, collectionId) => {
  try {
    const response = await databases.deleteDocument(config.databaseId, collectionId, documentId)
    return null
  } catch (error) {
    return error
  }
}

// Auth
export async function createUser(email, password, username) {
  try {
    const newAccount = await account.create(
      ID.unique(),
      email,
      password,
      username
    );

    if (!newAccount) throw Error;

    const avatarUrl = avatars.getInitials(username);

    await signIn(email, password);

    const newUser = await databases.createDocument(
      config.databaseId,
      config.userCollectionId,
      ID.unique(),
      {
        accountId: newAccount.$id,
        email: email,
        username: username,
        avatar: avatarUrl,
        permissions: 'user'
      }
    );

    return newUser;
  } catch (error) {
    throw new Error(error);
  }
}

export async function signIn(email, password) {
  try {
    const session = await account.createEmailPasswordSession(
      email,
      password
    );

    return session;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getAccount() {
  try {
    const currentAccount = await account.get();

    return currentAccount;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getCurrentUser() {
  try {
    const currentAccount = await getAccount();
    if (!currentAccount) throw Error;

    const currentUser = await databases.listDocuments(
      config.databaseId,
      config.userCollectionId,
      [Query.equal("accountId", currentAccount.$id)]
    );

    if (!currentUser) throw Error;

    return currentUser.documents[0];
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const signOut = async () => {
  try {
    const session = await account.deleteSession('current')
    localStorage.clear()
    return session;
  } catch (error) {
    throw new Error(error)
  }
}

export const uploadPermissionRecord = async (permissionName, customAccess, pageAccess) => {
  try {
    // Search for an existing document with the given permissionName
    const searchResponse = await databases.listDocuments(config.databaseId, config.permissionsCollectionId, [
      Query.equal('permissionName', permissionName),
    ]);

    // Check if a matching document is found
    if (searchResponse.total > 0) {
      const documentId = searchResponse.documents[0].$id;

      // Update the existing document
      const updateResponse = await databases.updateDocument(
        config.databaseId,
        config.permissionsCollectionId,
        documentId,
        {
          customAccess: customAccess,
          pageAccess: JSON.stringify(pageAccess),
        }
      );

      // Output the updated document ID
      return updateResponse;
    } else {
      // Create a new document
      const createResponse = await databases.createDocument(
        config.databaseId,
        config.permissionsCollectionId,
        ID.unique(),
        {
          permissionName: permissionName,
          customAccess: customAccess,
          pageAccess: JSON.stringify(pageAccess),
        }
      );

      // Output the newly created document ID
      return createResponse;
    }
  } catch (error) {
    return error;
  }
};

export const getPermissionGroups = async () => {
  const permissions = await getAllDocuments(config.permissionsCollectionId, null, [{ customAccess: false }])

  var groups = permissions.map((permission) => {
    return permission.permissionName
  })

  return groups
} 

// ======================================== REFACTOR ======================================== //

export async function getBusinessQuestionaire(id) {
  try {
    const document = await databases.getDocument(config.databaseId, config.businessQuestionaireCollectionId, id);

    // Check if the document exists
    if (!document.$id) throw new Error('Document not found');

    // Convert document.data to JSON format
    const parsedDocumentData = JSON.parse(document.data);

    // Return the document with parsed data
    return { ...document, data: parsedDocumentData };
  } catch (error) {
    console.error('Error fetching document:', error);
    return null;
  }
}

export const uploadPersonalQuestionaire = async (formData) => {
  try {
    // Call the createDocument method to create a new document
    const response = await databases.createDocument(
      config.databaseId,
      config.personalQuestionaireCollectionId,
      ID.unique(),
      {
        data: JSON.stringify(formData)
      }
    );

    console.log(response)

    // Output the newly created document ID
    return null
  } catch (error) {
    return error
  }
}

export async function getPersonalQuestionaire(id) {
  try {
    const document = await databases.getDocument(config.databaseId, config.personalQuestionaireCollectionId, id);

    // Check if the document exists
    if (!document.$id) throw new Error('Document not found');

    // Convert document.data to JSON format
    const parsedDocumentData = JSON.parse(document.data);

    // Return the document with parsed data
    return { ...document, data: parsedDocumentData };
  } catch (error) {
    console.error('Error fetching document:', error);
    return null;
  }
}

export const getAllPersonalQuestionaires = async () => {
  try {
    const databaseId = config.databaseId;
    const collectionId = config.personalQuestionaireCollectionId;
    let offset = 0;
    let allDocuments = [];

    // Fetch documents in batches until all documents are retrieved
    while (true) {
      const response = await databases.listDocuments(databaseId, collectionId, [
        Query.limit(25),
        Query.offset(offset)
      ]);

      // Parse the 'data' field of each document to JSON format
      const parsedDocuments = response.documents.map(doc => ({
        ...doc,
        data: JSON.parse(doc.data)
      }));

      // Append parsed documents to the array
      allDocuments = allDocuments.concat(parsedDocuments);

      // Update offset for the next batch
      offset += 25;

      // Break the loop if all documents have been fetched
      if (offset >= response.total) {
        break;
      }
    }
    return allDocuments;
  } catch (error) {
    throw new Error(error);
  }
};

export const deletePersonalQuestionaire = async (id) => {
  try {
    const response = await databases.deleteDocument(config.databaseId, config.personalQuestionaireCollectionId, id)
    return null
  } catch (error) {
    return error
  }
}

export const uploadPersonalIntake = async (formData) => {
  try {
    // Call the createDocument method to create a new document
    const response = await databases.createDocument(
      config.databaseId,
      config.personalIntakeCollectionId,
      ID.unique(),
      {
        data: JSON.stringify(formData)
      }
    );

    console.log(response)

    // Output the newly created document ID
    return null
  } catch (error) {
    return error
  }
}

export const uploadBusinessIntake = async (formData) => {
  try {
    // Call the createDocument method to create a new document
    const response = await databases.createDocument(
      config.databaseId,
      config.businessIntakeCollectionId,
      ID.unique(),
      {
        data: JSON.stringify(formData)
      }
    );

    console.log(response)

    // Output the newly created document ID
    return null
  } catch (error) {
    return error
  }
}