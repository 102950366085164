import React from 'react';

const ServiceCard = ({ icon: Icon, title, description }) => {
  return (
    <div className='mt-[40px]'>
      <div className='float-left flex items-center justify-center w-[64px] h-[64px] border-solid border-[2px] border-[#d0ecf9] rounded-[50px] hover:bg-[#F89E34] hover:border-[#F89E34] transition duration-500 ease-in-out'>
        <Icon className='text-[#F89E34] hover:text-[#fff] text-[32px]' />
      </div>
      <h4 className='ml-[85px] font-[700] mb-[10px] text-[18px]'>
        <a className='text-[#F89E34] hover:text-[#343a40]' href="">
          {title}
        </a>
      </h4>
      <p className='ml-[85px] text-[14px]'>{description}</p>
    </div>
  );
};

export default ServiceCard;
