
export { default as Dashboard } from './Dashboard';
export { default as AccessControl } from './Backend/AccessControl/AccessControl'
export { default as SignIn } from './auth/SignIn';
export { default as SignUp } from './auth/SignUp'; 

export { default as Questionaires } from './Backend/Questionaires/Questionaires'
export { default as Intakes } from './Backend/Intakes/Intakes'

export { default as Home } from './home/Home'
export { default as WebEditor } from './Backend/WebEditor/WebEditor'

// People
export { default as RichardWhite } from './home/people/RichardWhite'

// Services
export { default as AccountingAndAdvisoryServices } from './home/AccountingAndAdvisoryServices'
export { default as PersonalFinancialPlanning } from './home/PersonalFinancialPlanning'
export { default as TaxPlanningAndPreparation } from './home/TaxPlanningAndPreparation'

// Tax Center
export { default as TrackYourRefund } from './home/TrackYourRefund'
export { default as TaxDueDates } from './home/TaxDueDates'
export { default as IRSTaxFormsAndPublications } from './home/IRSTaxFormsAndPublications'
export { default as RecordRetentionGuide } from './home/RecordRetentionGuide'
export { default as StateTaxForms } from './home/StateTaxForms'

// Portal
export { default as FormLanding } from './home/FormLanding'
export { default as PersonalIntake } from './home/forms/PersonalIntake'
export { default as PersonalQuestionaire } from './home/forms/PersonalQuestionaire'
export { default as BusinessIntake } from './home/forms/BusinessIntake'
export { default as BusinessQuestionaire } from './home/forms/BusinessQuestionaire'

