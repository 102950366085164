import React from 'react'

const Button = ({ bgColor, color, size, text, borderRadius, otherStyles }) => {
  return (
    <button
      type='button'
      style={{ backgroundColor: bgColor, color }}
      className={`text-${size} p-3 hover:drop-shadow-xl rounded-${borderRadius} ${otherStyles}`}
    >
      {text}
    </button>
  )
}

export default Button