import React from 'react'
import Home from '../../home/Home'

const WebEditor = () => {
    return (
        <div className='mx-[15%] relative' style={{ transform: 'translateX(-15%)' }}>
            <Home editing={true} />
        </div>

    )
}

export default WebEditor