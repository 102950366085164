import React, { useEffect, useState } from 'react'
import { Button, DateField, FormField } from '../../../components'
import YesNoButton from '../../../components/YesNoButton'
import DropdownField from '../../../components/DropdownField'
import { uploadPersonalQuestionaire } from '../../../lib/appwrite'
import { useNavigate } from 'react-router-dom'

const PersonalQuestionaire = () => {
  const navigate = useNavigate()
  const [individual_services, setindividual_services] = useState([])
  const [tempAddService, setTempAddService] = useState('')

  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone: '',
    fax: '',
    preferred_contact_method: '',
    street_address: '',
    city: '',
    state: '',
    zipcode: '',
    billing_address_same: '',
    billing_street_address: '',
    billing_city: '',
    billing_state: '',
    billing_zipcode: '',
    individual_services: '',
    income_range: '',
    years_in_current_home: 0,
    plan_on_moving: '',
    outstanding_litigations: '',
    outstanding_liens: '',
    tax_letters: '',
    firm_goal: '',
    workd_with_others: '',
    heard_about_from: ''
  })
  const [formDataErrors, setFormDataErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    preferred_contact_method: false,
    street_address: false,
    city: false,
    state: false,
    zipcode: false,
    billing_address_same: false,
    billing_street_address: false,
    billing_city: false,
    billing_state: false,
    billing_zipcode: false,
    individual_services: false,
    income_range: false,
    outstanding_litigations: false,
    outstanding_liens: false,
    tax_letters: false,
    heard_about_from: false
  })

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      individual_services
    }));
  }, [individual_services]);

  const addService = (service) => {
    if (service === '') { return }

    // Check if service is already present in individual_services
    const index = individual_services.indexOf(service);

    // If service is present, remove it
    if (index !== -1) {
      const updatedServices = [...individual_services];
      updatedServices.splice(index, 1);
      setindividual_services(updatedServices);
    } else {
      // If service is not present, add it
      setindividual_services([...individual_services, service]);
    }

    setTempAddService('')
  };

  const submit = async () => {
    if (formData.billing_address_same === 'same') {
      // Use a functional update to ensure the state is updated correctly
      setFormData(prevFormData => ({
        ...prevFormData,
        billing_street_address: prevFormData.street_address,
        billing_city: prevFormData.city,
        billing_state: prevFormData.state,
        billing_zipcode: prevFormData.zipcode
      }));
    }

    // Use a callback to run the validation checks after state updates
    setFormData(prevFormData => {
      const newFormDataErrors = { ...formDataErrors };

      newFormDataErrors.first_name = prevFormData.first_name === '';
      newFormDataErrors.last_name = prevFormData.last_name === '';
      newFormDataErrors.email = prevFormData.email === '';
      newFormDataErrors.phone = prevFormData.phone === '';
      newFormDataErrors.preferred_contact_method = prevFormData.preferred_contact_method === '';
      newFormDataErrors.street_address = prevFormData.street_address === '';
      newFormDataErrors.city = prevFormData.city === '';
      newFormDataErrors.state = prevFormData.state === '';
      newFormDataErrors.zipcode = prevFormData.zipcode === '';
      newFormDataErrors.billing_address_same = prevFormData.billing_address_same === '';
      newFormDataErrors.billing_street_address = prevFormData.billing_street_address === '';
      newFormDataErrors.billing_city = prevFormData.billing_city === '';
      newFormDataErrors.billing_state = prevFormData.billing_state === '';
      newFormDataErrors.billing_zipcode = prevFormData.billing_zipcode === '';
      newFormDataErrors.individual_services = prevFormData.individual_services.length === 0;
      newFormDataErrors.income_range = prevFormData.income_range === '';
      newFormDataErrors.outstanding_litigations = prevFormData.outstanding_litigations === '';
      newFormDataErrors.outstanding_liens = prevFormData.outstanding_liens === '';
      newFormDataErrors.tax_letters = prevFormData.tax_letters === '';
      newFormDataErrors.heard_about_from = prevFormData.heard_about_from === '';

      setFormDataErrors(newFormDataErrors);

      // Check if any errors exist
      if (Object.values(newFormDataErrors).some(error => error)) {
        return; // Return early if any error is true
      }

      uploadPersonalQuestionaire(prevFormData);

      navigate('/')

      return prevFormData;
    });
  };




  return (
    <div className='px-[15%] p-[60px] space-y-4'>
      <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Questionaire</h1>

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Primary Contact Information</h2>

      <div className='grid grid-cols-2'>
        <FormField
          title="First Name"
          value={formData.first_name}
          handleChangeText={(e) => setFormData({ ...formData, first_name: e })}
          placeholder=""
          error={formDataErrors.first_name}
          required={true}
          otherStyles="pr-1"
        />
        <FormField
          title="Middle Name"
          value={formData.middle_name}
          handleChangeText={(e) => setFormData({ ...formData, middle_name: e })}
          placeholder=""
          otherStyles="pl-1"
        />

        <FormField
          title="Last Name"
          value={formData.last_name}
          handleChangeText={(e) => setFormData({ ...formData, last_name: e })}
          placeholder=""
          error={formDataErrors.last_name}
          required={true}
          otherStyles="pr-1"
        />
      </div>

      <FormField
        title="Email"
        value={formData.email}
        handleChangeText={(e) => setFormData({ ...formData, email: e })}
        placeholder=""
        error={formDataErrors.email}
        required={true}
      />

      <div className='grid grid-cols-2'>
        <FormField
          title="Phone"
          value={formData.phone}
          handleChangeText={(e) => setFormData({ ...formData, phone: e })}
          placeholder=""
          error={formDataErrors.phone}
          required={true}
          otherStyles="pr-1"
        />
        <FormField
          title="Fax"
          value={formData.fax}
          handleChangeText={(e) => setFormData({ ...formData, fax: e })}
          placeholder=""
          otherStyles="pl-1"
        />
      </div>

      <YesNoButton
        title="Preferred Method of Contact"
        option1="email"
        option2="phone"
        handleChangeText={(e) => setFormData({ ...formData, preferred_contact_method: e })}
        error={formDataErrors.preferred_contact_method}
        required={true}
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Address</h2>

      <FormField
        title="Address"
        value={formData.street_address}
        handleChangeText={(e) => setFormData({ ...formData, street_address: e })}
        placeholder=""
        error={formDataErrors.street_address}
        required={true}
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.city}
          handleChangeText={(e) => setFormData({ ...formData, city: e })}
          placeholder=""
          error={formDataErrors.city}
          required={true}
        />
        <FormField
          title="State"
          value={formData.state}
          handleChangeText={(e) => setFormData({ ...formData, state: e })}
          placeholder=""
          error={formDataErrors.state}
          required={true}
        />
      </div>

      <FormField
        title="Zip"
        value={formData.zipcode}
        handleChangeText={(e) => setFormData({ ...formData, zipcode: e })}
        placeholder=""
        error={formDataErrors.zipcode}
        required={true}
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        handleChangeText={(e) => setFormData({ ...formData, billing_address_same: e })}
        error={formDataErrors.billing_address_same}
        required={true}
      />
      {formData.billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, billing_street_address: e })}
            placeholder=""
            error={formDataErrors.billing_street_address}
            required={true}
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.billing_city}
              handleChangeText={(e) => setFormData({ ...formData, billing_city: e })}
              placeholder=""
              error={formDataErrors.billing_city}
              required={true}
            />

            <FormField
              title="State"
              value={formData.billing_state}
              handleChangeText={(e) => setFormData({ ...formData, billing_state: e })}
              placeholder=""
              error={formDataErrors.billing_state}
              required={true}
            />
          </div>
          <FormField
            title="Zip"
            value={formData.billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, billing_zipcode: e })}
            placeholder=""
            error={formDataErrors.billing_zipcode}
            required={true}
          />
        </div>
      )}

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>General Information</h2>

      <div>
        <div className='flex'>
          <p className="text-base dark:text-gray-100 font-medium">Individual Services</p>
          <p className={`text-red-500 font-medium pl-2`}>*</p>
        </div>
        <div className='flex space-x-2'>
          {individual_services.map((service, index) => (
            <div key={index} className='' onClick={() => addService(service)}>
              <Button
                bgColor="rgba(107,114,128,0.1)"
                text={service}
                borderRadius="[10px]"
                size="md"
              />
            </div>
          ))}
        </div>
        <DropdownField
          title=""
          selected={tempAddService}
          setSelected={setTempAddService}
          options={[
            "Estate & Retirement Planning",
            "Personal Financial Planning",
            "Tax Preparation"
          ]}
          handleChangeText={(e) => addService(e)}
          error={formDataErrors.individual_services}
        />
      </div>

      <DropdownField
        title="Income Range"
        selected={formData.income_range}
        setSelected={(e) => setFormData({ ...formData, income_range: e })}
        options={[
          "$0 - $90k",
          "$91k - $190k",
          "$191k - $365k",
          "$366k - $465k",
          "$466k - $700k",
          "$700k+"
        ]}
        handleChangeText={(e) => setFormData({ ...formData, income_range: e })}
        error={formDataErrors.income_range}
        required={true}
      />

      <FormField
        title="Years living in current home"
        value={formData.years_in_current_home}
        handleChangeText={(e) => setFormData({ ...formData, years_in_current_home: e })}
        placeholder=""
        type="number"
      />

      <YesNoButton
        title="Do you plan on moving?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, plan_on_moving: e })}
      />

      <YesNoButton
        title="Are there any outstanding litigations against you?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, outstanding_litigations: e })}
        error={formDataErrors.outstanding_litigations}
        required={true}
      />

      <YesNoButton
        title="Are there any outstanding tax liens or liabilities against you?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, outstanding_liens: e })}
        error={formDataErrors.outstanding_liens}
        required={true}
      />

      <YesNoButton
        title="Have you received any tax letters within the last 90 days?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, tax_letters: e })}
        error={formDataErrors.tax_letters}
        required={true}
      />

      <FormField
        title="Briefly describe what you would like our firm to accomplish for you:"
        value={formData.firm_goal}
        handleChangeText={(e) => setFormData({ ...formData, firm_goal: e })}
        placeholder=""
      />

      <YesNoButton
        title="Have you ever worked with another accountant, business/personal advisor or tax advisor reguarding this matter?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, tax_letters: e })}
      />

      <DropdownField
        title="How did you hear about our firm?"
        selected={formData.heard_about_from}
        setSelected={(e) => setFormData({ ...formData, heard_about_from: e })}
        options={["Search Engine", "Social Media", "Referral", "Other"]}
        handleChangeText={(e) => setFormData({ ...formData, heard_about_from: e })}
        error={formDataErrors.heard_about_from}
        required={true}
      />

      <div className='flex justify-center items-center'>
        <div onClick={() => submit()}>
          <Button
            bgColor="#F89E34"
            text="Submit"
            borderRadius="[10px]"
            size="md"
          />
        </div>
      </div>

    </div>
  )
}

export default PersonalQuestionaire