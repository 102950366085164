import React from 'react'

const TaxDueDates = () => {
  return (
    <div>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Tax Due Dates</h1>
        <table>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">March 2024</td>
          </tr>
          <tr>
            <td><strong>March 11</strong></td>
            <td><strong>Individuals -</strong> Report February tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>March 15</strong></td>
            <td><strong>Calendar-year S corporations -</strong> File a 2023 income tax return (Form 1120-S) and provide each shareholder with a copy of Schedule K-1 (Form 1120S) or a substitute Schedule K-1 — or file for an automatic six-month extension (Form 7004). Pay any tax due.</td>
          </tr>
          <tr>
            <td><strong>March 15</strong></td>
            <td><strong>Calendar-year partnerships -</strong> File a 2023 income tax return (Form 1065 or Form 1065-B) and provide each partner with a copy of Schedule K1 (Form 1065) or a substitute Schedule K1 — or request an automatic six-month extension (Form 7004).</td>
          </tr>
          <tr>
            <td><strong>March 15</strong></td>
            <td><strong>Employers -</strong> Deposit Social Security, Medicare and withheld income taxes for February if the monthly deposit rule applies</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">April 2024</td>
          </tr>
          <tr>
            <td><strong>April 1</strong></td>
            <td><strong>Employers -</strong> Electronically file 2023 Form 1097, Form 1098, Form 1099 (other than those with an earlier deadline) and Form W-2G</td>
          </tr>
          <tr>
            <td><strong>April 10</strong></td>
            <td><strong>Individuals -</strong> Report March tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>April 15</strong></td>
            <td><strong>Individuals -</strong> File a 2023 income tax return (Form 1040 or Form 1040-SR) or file for an automatic six-month extension (Form 4868). (Taxpayers who live outside the United States and Puerto Rico or serve in the military outside these two locations are allowed an automatic two-month extension without requesting an extension.) Pay any tax due.</td>
          </tr>
          <tr>
            <td><strong>April 15</strong></td>
            <td><strong>Individuals -</strong> Pay the first installment of 2024 estimated taxes (Form 1040-ES) if not paying income tax through withholding or not paying sufficient income tax through withholding.</td>
          </tr>
          <tr>
            <td><strong>April 15</strong></td>
            <td><strong>Individuals -</strong> Make 2023 contributions to a traditional IRA or Roth IRA (even if a 2023 income tax return extension is filed).</td>
          </tr>
          <tr>
            <td><strong>April 15</strong></td>
            <td><strong>Individuals -</strong> Make 2023 contributions to a SEP or certain other retirement plans (unless a 2023 income tax return extension is filed).</td>
          </tr>
          <tr>
            <td><strong>April 15</strong></td>
            <td><strong>Individuals -</strong> File a 2023 gift tax return (Form 709) or file for an automatic six-month extension (Form 8892). Pay any gift tax due. File for an automatic six-month extension (Form 4868) to extend both Form 1040 and Form 709 if no gift tax is due.</td>
          </tr>
          <tr>
            <td><strong>April 15</strong></td>
            <td><strong>Household Employers -</strong> File Schedule H, if wages paid equal $2,600 or more in 2023 and Form 1040 isn’t required to be filed. For those filing Form 1040, Schedule H is to be submitted with the return so is extended if the return is extended.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">May 2024</td>
          </tr>
          <tr>
            <td><strong>May 10</strong></td>
            <td><strong>Individuals -</strong> Report April tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>May 10</strong></td>
            <td><strong>Educational institutions -</strong> File Form 941 for the first quarter of 2024. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until May 10 to file the return.</td>
          </tr>
          <tr>
            <td><strong>May 15</strong></td>
            <td><strong>Estate and trust income tax returns -</strong> File Form 1041 and pay any tax, interest, and penalties due. Provide each beneficiary with a copy of Schedule K-1 (Form 1041) or a substitute Schedule K-1.</td>
          </tr>
          <tr>
            <td><strong>May 15</strong></td>
            <td><strong>Household Employers -</strong> If you paid cash wages of $2,100 or more in 2023 to a household employee, you must file Schedule H (Form 1040) with your income tax return and report any household employment taxes. Report any federal unemployment (FUTA) tax on Schedule H (Form 1040) if you paid total cash wages of $1,000 or more in any calendar quarter of 2023 or 2024 to household employees.</td>
          </tr>
          <tr>
            <td><strong>May 15</strong></td>
            <td><strong>Employers -</strong> Nonpayroll taxes - If the monthly deposit rule applies, deposit the tax for payments in April.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">June 2024</td>
          </tr>
          <tr>
            <td><strong>June 17</strong></td>
            <td><strong>Individuals -</strong> File a 2023 individual income tax return (Form 1040 or Form 1040-SR) or file for a four-month extension (Form 4868) if you live outside the United States and Puerto Rico or you serve in the military outside those two locations. Pay any tax, interest and penalties due.</td>
          </tr>
          <tr>
            <td><strong>June 17</strong></td>
            <td><strong>Individuals -</strong> Pay the second installment of 2024 estimated taxes (Form 1040-ES) if not paying income tax through withholding or not paying sufficient income tax through withholding.</td>
          </tr>
          <tr>
            <td><strong>June 17</strong></td>
            <td><strong>Calendar-year corporations -</strong> Pay the second installment of 2024 estimated income taxes, completing Form 1120-W for the corporation’s records.</td>
          </tr>
          <tr>
            <td><strong>June 17</strong></td>
            <td><strong>Employers -</strong> Deposit Social Security, Medicare and withheld income taxes for May if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>June 17</strong></td>
            <td><strong>Employers -</strong> Deposit nonpayroll withheld income tax for May if the monthly deposit rule applies.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">July 2024</td>
          </tr>
          <tr>
            <td><strong>July 10</strong></td>
            <td><strong>Individuals -</strong> Report June tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>July 15</strong></td>
            <td><strong>Employers -</strong> Deposit Social Security, Medicare and withheld income taxes for June if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>July 15</strong></td>
            <td><strong>Employers -</strong> Deposit nonpayroll withheld income tax for June if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>July 31</strong></td>
            <td><strong>Employers -</strong> Report Social Security and Medicare taxes and income tax withholding for second quarter 2024 (Form 941) and pay any tax due if all of the associated taxes due weren’t deposited on time and in full.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">August 2024</td>
          </tr>
          <tr>
            <td><strong>August 12</strong></td>
            <td><strong>Individuals -</strong> Report July tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>August 12</strong></td>
            <td><strong>Educational institutions -</strong> Deposit federal unemployment (FUTA) tax owed through June if more than $500.</td>
          </tr>
          <tr>
            <td><strong>August 15</strong></td>
            <td><strong>Employers -</strong> Deposit Social Security, Medicare and withheld income taxes for July if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>August 15</strong></td>
            <td><strong>Employers -</strong> Deposit nonpayroll withheld income tax for July if the monthly deposit rule applies.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">September 2024</td>
          </tr>
          <tr>
            <td><strong>September 10</strong></td>
            <td><strong>Individuals -</strong> Report August tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Individuals -</strong> Pay the third installment of 2024 estimated taxes (Form 1040-ES), if not paying income tax through withholding or not paying sufficient income tax through withholding.</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Calendar-year corporations -</strong> Pay the third installment of 2024 estimated income taxes, completing Form 1120-W for the corporation’s records.</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Calendar-year S corporations -</strong> File a 2023 income tax return (Form 1120-S) and provide each shareholder with a copy of Schedule K-1 (Form 1120S) or a substitute Schedule K-1 if an automatic six-month extension was filed. Pay any tax, interest and penalties due.</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Calendar-year S corporations -</strong> Make contributions for 2023 to certain employer-sponsored retirement plans if an automatic six-month extension was filed.</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Calendar-year partnerships -</strong> File a 2023 income tax return (Form 1065 or Form 1065-B) and provide each partner with a copy of Schedule K1 (Form 1065) or a substitute Schedule K1 if an automatic six-month extension was filed.</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Employers -</strong> Deposit Social Security, Medicare and withheld income taxes for August if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>September 16</strong></td>
            <td><strong>Employers -</strong> Deposit nonpayroll withheld income tax for August if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>September 30</strong></td>
            <td><strong>Calendar-year trusts and estates -</strong> File a 2023 income tax return (Form 1041) if an automatic five-and-a-half-month extension was filed. Pay any tax, interest and penalties due.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">October 2024</td>
          </tr>
          <tr>
            <td><strong>October 10</strong></td>
            <td><strong>Individuals -</strong> Report September tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>October 15</strong></td>
            <td><strong>Individuals -</strong> File a 2023 income tax return (Form 1040 or Form 1040-SR) if an automatic six-month extension was filed (or if an automatic four-month extension was filed by a taxpayer living outside the United States and Puerto Rico). Pay any tax, interest and penalties due.</td>
          </tr>
          <tr>
            <td><strong>October 15</strong></td>
            <td><strong>Individuals -</strong> Make contributions for 2023 to certain existing retirement plans or establish and contribute to a SEP for 2023 if an automatic six-month extension was filed.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">November 2024</td>
          </tr>
          <tr>
            <td><strong>November 12</strong></td>
            <td><strong>Individuals -</strong> Report October tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>November 12</strong></td>
            <td><strong>Educational institutions -</strong> File Form 941 for the third quarter of 2024. Deposit any undeposited tax. (If your tax liability is less than $2,500, you can pay it in full with a timely filed return.) If you deposited the tax for the quarter in full and on time, you have until November 12 to file the return.</td>
          </tr>
          <tr>
            <td><strong>November 15</strong></td>
            <td><strong>Estate and trust income tax returns -</strong> File Form 1041 and pay any tax, interest, and penalties due. Provide each beneficiary with a copy of Schedule K-1 (Form 1041) or a substitute Schedule K-1.</td>
          </tr>
          <tr className='bg-blue-500 text-white'>
            <td colspan="2">December 2024</td>
          </tr>
          <tr>
            <td><strong>December 10</strong></td>
            <td><strong>Individuals -</strong> Report November tip income of $20 or more to employers (Form 4070).</td>
          </tr>
          <tr>
            <td><strong>December 16</strong></td>
            <td><strong>Calendar-year corporations -</strong> Pay the fourth installment of 2024 estimated income taxes, completing Form 1120-W for the corporation’s records.</td>
          </tr>
          <tr>
            <td><strong>December 16</strong></td>
            <td><strong>Employers -</strong> Deposit Social Security, Medicare and withheld income taxes for November if the monthly deposit rule applies.</td>
          </tr>
          <tr>
            <td><strong>December 16</strong></td>
            <td><strong>Employers -</strong> Deposit nonpayroll withheld income tax for November if the monthly deposit rule applies.</td>
          </tr>
        </table>
        {/* 
          
          
          
          
        </table> */}
      </div>
    </div>
  )
}

export default TaxDueDates