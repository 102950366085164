import React, { useState } from 'react'

const DropdownField = ({ selected, setSelected, title, options, handleChangeText, required, error }) => {

    const handleButtonSelect = (option, actionSource) => {
        setSelected(option)
        handleChangeText(option)
    }

    return (
        <div className='space-y-2'>
            <div className='flex'>
                <p className="text-base font-medium">{title}</p>
                <p className={`text-red-500 font-medium pl-2 ${required ? "block" : "hidden"}`}>*</p>
            </div>
            <div className={`border-2 border-black-200 bg-black-100 focus:border-secondary flex items-center justify-between rounded-2xl ${error ? "bg-red-500 bg-opacity-30 border-red-400 text-red-400" : "dark:text-white"}`}>
                <select
                    id="selection"
                    value={selected}
                    onChange={(e) => handleButtonSelect(e.target.value)}
                    className='flex-1 w-full h-10 px-4 rounded-2xl font-semibold text-base bg-transparent placeholder-gray-500'
                >
                    <option value=""></option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default DropdownField