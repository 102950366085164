import React from 'react'

const TaxPlanningAndPreparation = () => {
  return (
    <div className='bg-[#f9f9f9]'>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Tax Planning and Preparation</h1>
        <br></br>
        <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Expert Tax Services for Individuals and Businesses</h2>
        <br></br>
        <p>Navigating the complexities of tax laws can be overwhelming. Whether you're a business owner or an
          individual taxpayer, compliance with the IRS is crucial to avoid penalties and fines. At RM White CPA
          LLC, we specialize in providing comprehensive tax services to ensure compliance and maximize deductions
          and credits.</p>
        <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Our Tax Services Include:</h2>
        <ul className="list-disc pl-[40px] pb-2">
          <li><strong>Individual Tax Preparation:</strong> Benefit from professional tax preparation services tailored to your unique tax situation.</li>
          <li><strong>Business Tax Preparation:</strong> Stay compliant and avoid penalties with our expert tax preparation services for businesses.</li>
          <li><strong>Tax Planning</strong>: Strategically reduce your tax liability with proactive tax-saving strategies designed to benefit you.</li>
          <li><strong>Tax Relief:</strong> Resolve tax problems efficiently and discreetly, putting an end to IRS-related stress and issues.</li>
        </ul>
        <p>Our success is built on yours. Contact RM White CPA LLC today to discover how our comprehensive tax services can save you time, money, and stress.</p>
      </div>
    </div>
  )
}

export default TaxPlanningAndPreparation