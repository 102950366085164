import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const FormInput = ({ id, title, value, placeholder, handleChangeText, otherStyles, required, error, type }) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = type
    ? type
    : (title === 'Password' && !showPassword)
      ? 'password'
      : 'text';

  return (
    <div className={`space-y-2 ${otherStyles}`}>
      <div className='flex'>
        <p className="text-base dark:text-gray-100 font-medium">{title}</p>
        <p className={`text-red-500 font-medium pl-2 ${required ? "block" : "hidden"}`}>*</p>
      </div>

      <div className={`border-2 border-black-200 bg-black-100 focus:border-secondary flex items-center justify-between rounded-2xl ${error ? "bg-red-500 bg-opacity-30 border-red-400 text-red-400" : "dark:text-white"}`}>
        <input
          id={id}
          className="flex-1 w-full h-10 px-4 rounded-2xl font-semibold text-base bg-transparent placeholder-gray-500"
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleChangeText(e.target.value)}
          type={inputType}
        />

        {title === 'Password' && (
          <button
            onClick={() => setShowPassword(!showPassword)}
            className='pr-2'
          >
            {!showPassword ? (
              <FaEye
                className='dark:text-gray-100'
              />
            ) : (
              <FaEyeSlash
                className='dark:text-gray-100'
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default FormInput;