import React, { useState, useEffect } from 'react';
import { Header } from '../../../components';
import useAppwrite from '../../../lib/useAppwrite';
import { deleteDocument, config, getAllDocuments } from '../../../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../../contexts/ContextProvider';
import { FaEye, FaShareSquare } from 'react-icons/fa';
import { FaTrashCan } from "react-icons/fa6";
import EditableTable from '../../../components/EditableTable';
import ConfirmDelete from '../../../components/ConfirmDelete';
import BusinessQuestionaireDetails from './BusinessQuestionaireDetails';

const BusinessQuestionaires = () => {
  const { currentColor } = useStateContext();
  const [showDetails, setShowDetails] = useState(false)
  const [reset, setReset] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [questionaires, setQuestionaires] = useState([])
  const { data: data, isLoading, refetch } = useAppwrite(
    () => getAllDocuments(config.businessQuestionaireCollectionId)
  );
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    if (data) {
      var questionaireDataArray = []
      questionaireDataArray = data.map((questionaire) => {
        var jsonData = JSON.parse(questionaire.data)
        jsonData['$id'] = questionaire.$id;
        return jsonData
      })
      setQuestionaires(questionaireDataArray);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  const handleRowSelect = (data) => {
    setSelectedRowData(data);
  };

  const handleDelete = async () => {
    setConfirmDelete(false)
    const response = await deleteDocument(selectedRowData.$id, config.businessQuestionaireCollectionId)
    refetch()
    setSelectedRowData(null)
  }

  const shareLink = () => {
    const textToCopy = `https://www.rmwhitecpa.com/portal/b-${selectedRowData.$id}`

    navigator.clipboard.writeText(textToCopy).then(() => {
      // console.log("Text copied to clipboard successfully!");
      // Optionally, you can show a success message to the user
    }).catch(err => {
      console.error("Failed to copy text to clipboard: ", err);
      // Optionally, you can show an error message to the user
    });
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <div className={`${showDetails ? 'block' : 'hidden'} overflow-scroll`}>
        <BusinessQuestionaireDetails showDetails={showDetails} setShowDetails={setShowDetails} selectedRowData={selectedRowData} />
      </div>
      <Header category="" title="Business Questionaires" />

      <div className='flex flex-1 justify-end items-center my-2'>
        <div className={`${selectedRowData ? 'block' : 'hidden'} flex`}>
          <TooltipComponent content="Copy Intake Link" position='TopCenter'>
            <button
              type='button'
              onClick={() => shareLink()}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaShareSquare className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
          <TooltipComponent content="Show Details" position='TopCenter'>
            <button
              type='button'
              onClick={() => setShowDetails(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaEye className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
          <TooltipComponent content="Delete" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmDelete(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaTrashCan className='text-xl text-red-600' />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <EditableTable
        title=""
        data={questionaires}
        setData={setQuestionaires}
        rowActions={[]}
        keyMapping={{
          'Company Name': 'company_name',
          'Website': 'website',
          'Email': 'pc1_personal_email'
        }}
        onRowClick={handleRowSelect} // Assuming handleRowSelect is a defined function
        reset={reset}
        setReset={setReset}
        selectable={true}
      />
      <ConfirmDelete
        title="Confirm Deletion?"
        text="Are you sure you want to delete the following? This action cannot be undone."
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        handleConfirm={() => handleDelete()}
      />
    </div>
  );
};

export default BusinessQuestionaires;