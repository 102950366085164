import React from 'react'
import '../css/Home.css'
import { FaChevronRight, FaFacebook, FaInstagram, FaLinkedin, FaSkype, FaTwitter } from 'react-icons/fa'

const Footer = () => {
  return (
    <div>
      <div className='bg-[#E9D8C6] p-[50px] text-center text-[15px]'>
        <p className='text-[24px] font-semibold text-[#0f394c]'>Tax Deadline Ticker</p>
      </div>
      <div className='p-[60px] bg-[#fff] pb-[30px]'>
        <div className='grid-cols-4 flex w-full justify-between'>
          <div>
            <p className='text-[22px] font-semibold'>RMWHITE CPA</p>
            <p className='text-[#777777] text-[14px] pt-3'>
              <strong>Phone:</strong> +1 (410) 779-9335<br></br>
              <strong>Email:</strong> richard@rmwhitecpa.com<br></br>
            </p>
          </div>

          {/* Useful Links */}
          <div>
            <p className='text-[18px] font-semibold text-[#0f394c]'>Useful Links</p>
            <ul className='text-[14px] text-[#F89E34]'>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="/">Home</a>
              </li>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="#about">About us</a>
              </li>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="#services">Services</a>
              </li>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="#">Terms of service</a>
              </li>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="#">Privacy policy</a>
              </li>
            </ul>
          </div>

          {/* Our Services */}
          <div>
            <p className='text-[18px] font-semibold text-[#0f394c]'>Our Services</p>
            <ul className='text-[14px] text-[#F89E34]'>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="/services/accounting-and-advisory-services">Accounting and Advisory Services</a>
              </li>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="/services/personal-financial-planning">Personal Financial Planning</a>
              </li>
              <li className='flex items-center p-[10px] space-x-1'>
                <FaChevronRight />
                <a className='text-[#777777]' href="/services/tax-planning-and-preparation">Tax Planning and Preparation</a>
              </li>
            </ul>
          </div>

          {/* Our Social Networks */}
          <div className='items-center text-center'>
            <p className='text-[22px] font-semibold text-[#0f394c]'>Our Social Networks</p>
            <div className='mt-3 text-[18px] flex space-x-1 items-center w-full justify-center'>
              <a href="#" className='bg-[#F89E34] text-[#fff] p-2 rounded-md hover:bg-[#DA6826] hover:text-[#fff]'><FaTwitter /></a>
              <a href="#" className='bg-[#F89E34] text-[#fff] p-2 rounded-md hover:bg-[#DA6826] hover:text-[#fff]'><FaFacebook /></a>
              <a href="#" className='bg-[#F89E34] text-[#fff] p-2 rounded-md hover:bg-[#DA6826] hover:text-[#fff]'><FaInstagram /></a>
              <a href="#" className='bg-[#F89E34] text-[#fff] p-2 rounded-md hover:bg-[#DA6826] hover:text-[#fff]'><FaSkype /></a>
              <a href="#" className='bg-[#F89E34] text-[#fff] p-2 rounded-md hover:bg-[#DA6826] hover:text-[#fff]'><FaLinkedin /></a>
            </div>
            <div className='pt-10'>
              <a className='text-gray-400 hover:text-gray-500' href="/login">Login</a>
            </div>
          </div>

        </div>
      </div>

      <div className='bg-[#E9D8C6]'>
        <div className='mx-[84px] py-4 justify-between flex  text-xs'>
          <div>
            &copy; Copyright <strong><span>RMWhite CPA</span></strong>. All Rights Reserved
          </div>
          <div>
            Powered by <a href="#">Streamline Automations LLC</a>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Footer