import React from 'react'

const PersonalFinancialPlanning = () => {
  return (
    <div className='bg-[#f9f9f9]'>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Personal Financial Planning</h1>
        <br></br>
        <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Achieve Financial Independence with RM White CPA LLC</h2>
        <p>Are you striving for financial independence? Whether your goal is a comfortable retirement, funding your
          children's education, or securing your legacy, we understand that the path to financial freedom can be
          complex. At RM White CPA LLC, we specialize in guiding our clients towards the financial independence
          they deserve after a lifetime of hard work.</p>
        <br></br>
        <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Our Services:</h2>
        <ul className='list-disc pl-[40px] pb-2'>
          <li><strong>Comprehensive Personal Financial Planning:</strong> We develop tailored financial plans to help you achieve your goals.</li>
          <li><strong>Regular Plan Reviews:</strong> As your life evolves, we'll review and adjust your plan to ensure it stays aligned with your objectives.</li>
          <li><strong>Tax Planning Integration:</strong> We seamlessly integrate your financial and tax plans, optimizing your tax strategies.</li>
          <li><strong>Wealth Preservation:</strong> Our experts analyze estate, gift, and trust tax strategies to help you preserve your wealth.</li>
          <li><strong>Budget Analysis:</strong> We compare your current budget with your retirement budget, ensuring a smooth transition.</li>
          <li><strong>Retirement Income Distribution:</strong> We create personalized retirement income plans that align with your long-term goals.</li>
        </ul>
        <p>Let us help you navigate the complexities of financial planning while you focus on living your life to the fullest. Partner with RM White CPA LLC for a secure financial future.</p>
      </div>

    </div>
  )
}

export default PersonalFinancialPlanning