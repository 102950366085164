import React from 'react'

const StateTaxForms = () => {
  return (
    <div className='bg-[#f9f9f9]'>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>State Tax Forms</h1>
        <table>
          <tr>
            <th>State</th>
          </tr>
          <tr>
            <td><a href="https://revenue.alabama.gov/" target="_blank">Alabama</a></td>
          </tr>
          <tr>
            <td><a href="http://www.tax.alaska.gov/programs/forms.aspx" target="_blank">Alaska</a></td>
          </tr>
          <tr>
            <td><a href="https://azdor.gov/forms" target="_blank">Arizona</a></td>
          </tr>
          <tr>
            <td><a href="https://www.dfa.arkansas.gov/services/category/taxes/" target="_blank">Arkansas</a></td>
          </tr>
          <tr>
            <td><a href="https://www.ftb.ca.gov/" target="_blank">California</a></td>
          </tr>
          <tr>
            <td><a href="https://tax.colorado.gov/" target="_blank">Colorado</a></td>
          </tr>
          <tr>
            <td><a href="http://www.ct.gov/drs/cwp/view.asp?a=1509&q=443198" target="_blank">Connecticut</a></td>
          </tr>
          <tr>
            <td><a href="http://revenue.delaware.gov/" target="_blank">Delaware</a></td>
          </tr>
          <tr>
            <td><a href="http://floridarevenue.com/Pages/forms_index.aspx" target="_blank">Florida</a></td>
          </tr>
          <tr>
            <td><a href="http://dor.georgia.gov/" target="_blank">Georgia</a></td>
          </tr>
          <tr>
            <td><a href="http://tax.hawaii.gov/" target="_blank">Hawaii</a></td>
          </tr>
          <tr>
            <td><a href="https://tax.idaho.gov/" target="_blank">Idaho</a></td>
          </tr>
          <tr>
            <td><a href="https://www2.illinois.gov/rev/Pages/default.aspx" target="_blank">Illinois</a></td>
          </tr>
          <tr>
            <td><a href="http://www.in.gov/dor/3489.htm" target="_blank">Indiana</a></td>
          </tr>
          <tr>
            <td><a href="https://tax.iowa.gov/" target="_blank">Iowa</a></td>
          </tr>
          <tr>
            <td><a href="http://www.ksrevenue.org/forms.html" target="_blank">Kansas</a></td>
          </tr>
          <tr>
            <td><a href="https://revenue.ky.gov/Pages/index.aspx" target="_blank">Kentucky</a></td>
          </tr>
          <tr>
            <td><a href="http://www.rev.state.la.us/sections/taxforms/" target="_blank">Louisiana</a></td>
          </tr>
          <tr>
            <td><a href="https://www.maine.gov/revenue/" target="_blank">Maine</a></td>
          </tr>
          <tr>
            <td><a href="http://taxes.marylandtaxes.com/Individual_Taxes/General_Information/Individual_Tax_Forms_and_Instructions/" target="_blank">Maryland</a></td>
          </tr>
          <tr>
            <td><a href="http://www.mass.gov/dor/forms/" target="_blank">Massachusetts</a></td>
          </tr>
          <tr>
            <td><a href="http://www.michigan.gov/treasury" target="_blank">Michigan</a></td>
          </tr>
          <tr>
            <td><a href="https://www.revenue.state.mn.us/minnesota-department-revenue" target="_blank">Minnesota</a></td>
          </tr>
          <tr>
            <td><a href="http://www.dor.ms.gov/Pages/default.aspx" target="_blank">Mississippi</a></td>
          </tr>
          <tr>
            <td><a href="http://dor.mo.gov/" target="_blank">Missouri</a></td>
          </tr>
          <tr>
            <td><a href="http://revenue.mt.gov/home/forms.aspx" target="_blank">Montana</a></td>
          </tr>
          <tr>
            <td><a href="http://www.revenue.state.ne.us/tax/current/current.html" target="_blank">Nebraska</a></td>
          </tr>
          <tr>
            <td><a href="http://tax.nv.gov/Forms/" target="_blank">Nevada</a></td>
          </tr>
          <tr>
            <td><a href="http://www.nh.gov/revenue/forms/index.htm" target="_blank">New Hampshire</a></td>
          </tr>
          <tr>
            <td><a href="https://www.nj.gov/treasury/taxation/" target="_blank">New Jersey</a></td>
          </tr>
          <tr>
            <td><a href="http://www.tax.newmexico.gov/forms-publications.aspx" target="_blank">New Mexico</a></td>
          </tr>
          <tr>
            <td><a href="http://www.tax.ny.gov/forms/" target="_blank">New York</a></td>
          </tr>
          <tr>
            <td><a href="http://www.dor.state.nc.us/forms/" target="_blank">North Carolina</a></td>
          </tr>
          <tr>
            <td><a href="http://www.nd.gov/tax/" target="_blank">North Dakota</a></td>
          </tr>
          <tr>
            <td><a href="http://www.tax.ohio.gov/Forms.aspx" target="_blank">Ohio</a></td>
          </tr>
          <tr>
            <td><a href="http://www.ok.gov/tax" target="_blank">Oklahoma</a></td>
          </tr>
          <tr>
            <td><a href="https://www.oregon.gov/dor/forms/pages/default.aspx" target="_blank">Oregon</a></td>
          </tr>
          <tr>
            <td><a href="https://www.revenue.pa.gov/LookingFor/TaxForms-Info/Pages/default.aspx" target="_blank">Pennsylvania</a></td>
          </tr>
          <tr>
            <td><a href="https://tax.ri.gov/" target="_blank">Rhode Island</a></td>
          </tr>
          <tr>
            <td><a href="http://www.sctax.org/tax/index" target="_blank">South Carolina</a></td>
          </tr>
          <tr>
            <td><a href="https://dor.sd.gov/" target="_blank">South Dakota</a></td>
          </tr>
          <tr>
            <td><a href="http://www.tn.gov/revenue/" target="_blank">Tennessee</a></td>
          </tr>
          <tr>
            <td><a href="http://comptroller.texas.gov/taxes/" target="_blank">Texas</a></td>
          </tr>
          <tr>
            <td><a href="http://tax.utah.gov/forms/" target="_blank">Utah</a></td>
          </tr>
          <tr>
            <td><a href="http://tax.vermont.gov/" target="_blank">Vermont</a></td>
          </tr>
          <tr>
            <td><a href="http://www.tax.virginia.gov/content/forms" target="_blank">Virginia</a></td>
          </tr>
          <tr>
            <td><a href="https://dor.wa.gov/" target="_blank">Washington</a></td>
          </tr>
          <tr>
            <td><a href="http://tax.wv.gov/Pages/default.aspx" target="_blank">West Virginia</a></td>
          </tr>
          <tr>
            <td><a href="https://www.revenue.wi.gov/index.html" target="_blank">Wisconsin</a></td>
          </tr>
          <tr>
            <td><a href="https://revenue.wyo.gov/home" target="_blank">Wyoming</a></td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default StateTaxForms