import React from 'react'

const RichardWhite = () => {
  return (
    <div className='bg-[#f9f9f9]'>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Founder and Managing Member</h1>
        <p>Richard is a Certified Public Accountant (CPA) licensed in the states of New York, Texas, and Maryland, and a Chartered Global Managerial Accountant (CGMA). He holds a Masters in Business Administration and Theology Andrews University theological Seminary. He has also earned a Doctorate in Ministry with a focus on leadership and financial recovery. Mr. White has over 30 years of experience in the area of small business management, real estate, non-profit, corporate, and individual tax planning and personal financial consulting. He was awarded membership into The Honor Society of Phi Kappa Phi, is a member of the National Association of Black Accountants, Texas CPA society, and American Institute of Certified Accountants. He was awarded the Martin Luther King Jr. 2012 Freedom Legacy Award from Andrews University, and a nominee for the Michigan Campus’ Compact 2011-2012 Commitment to Service Award.</p>
      </div>
    </div>


  )
}

export default RichardWhite