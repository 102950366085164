import React from 'react';

const BigServiceCard = ({ icon: Icon, title, description, iconColor }) => {
  return (
    <div className='flex items-center hover:translate-y-[-5px] p-[30px] overflow-hidden rounded-[10px] shadow-md bg-[#fff] transition-all duration-300 ease-in-out'>
      <div className='relative left-[-50px]'>
        <Icon className={`text-[64px] transition duration-500 ${iconColor}`} />
      </div>
      <div>
        <h4 className='ml-[40px] font-[700] mb-[15px] text-[18px]'>
          <a className='hover:text-[#F89E34] text-[#111]' href="/services/accounting-and-advisory-services">
            {title}
          </a>
        </h4>
        <p className='text-[14px] ml-[40px] mb-0'>
          {description}
        </p>
      </div>
    </div>
  );
};

export default BigServiceCard;
