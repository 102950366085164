import React, { useState } from 'react'
import '../../css/Home.css'
import { Button, Footer, WebNavbar } from '../../components'
import hero_img from '../../assets/hero-img.png'
import AICPA_logo from '../../assets/seals/AICPA-logo.png'
import MACPA_logo from '../../assets/seals/MACPA-logo.png'
import MSATP_logo from '../../assets/seals/MSATP-logo.png'
import NABA_logo from '../../assets/seals/NABA-logo.png'
import quickbooks_logo from '../../assets/seals/quickbooks-logo.png'
import streamlineautomations_logo from '../../assets/seals/streamlineautomations-logo.png'
import noPhoto from '../../assets/noPhoto.png'
import richard_white_photo from '../../assets/team/richard-white.png'
import { FaFingerprint } from 'react-icons/fa'
import { IoCalendarOutline, IoChatboxEllipsesOutline, IoGiftOutline } from 'react-icons/io5'
import { LuAtom } from "react-icons/lu";
import { BsAppIndicator, BsCashStack } from 'react-icons/bs'
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'
import { FaFacebook, FaInstagram, FaLinkedin, FaSkype, FaTwitter } from 'react-icons/fa'
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md'
import counts_bg from '../../assets/counts-bg.jpg'
import testimonials_bg from '../../assets/cta-bg.jpg'
// import HorizontalSwiper from '../../components/HorizontalSwiper'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import ServiceCard from '../../components/ServiceCard'
import BigServiceCard from '../../components/BigServiceCard'
import TestimonialSwiper from '../../components/TestimonialSwiper'
import TeamCard from '../../components/TeamCard'


const Home = ({ editing }) => {
  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [heroHeader, setHeroHeader] = useState('Take the First Step Towards Success!')
  const [heroDescript, setHeroDescript] = useState("Are you ready to transform your business idea into a thriving enterprise? Contact R M White CPA today and embark on your journey to success with our expert Emerging Business Services. Let's turn your vision into a reality!")
  const [heroButtonOneText, setHeroButtonOneText] = useState("Get Started")
  const [heroButtonTwoText, setHeroButtonTwoText] = useState("Client Portal")
  const [aboutHeader, setAboutHeader] = useState("Your Partner in Building Success.")
  const [aboutDescriptionOne, setAboutDescriptionOne] = useState("At R M White CPA, we specialize in Emerging Business Services (EBS), which is an essential lifeline for new ventures. Our mission is clear: we aim to transform your business idea into a thriving reality within its critical first five years. We understand that 45% of small businesses face challenges during this period, which is why our EBS program is meticulously designed an tailored to your specific business needs to significantly boost your odds of success.")
  const [aboutDescriptionTwo, setAboutDescriptionTwo] = useState("With over 40 years in the business and a diverse array of small business experience, we are capable of offering the following:")
  const [services, setServices] = useState([
    {
      icon: FaFingerprint,
      title: "Tailored Business Models",
      description: "We can help you choose the most influential business model to establish the appropriate operational and organizational structure to ensure efficiency and profitability."
    },
    {
      icon: IoGiftOutline,
      title: "Advanced Accounting Solutions",
      description: "We ensure precise financial reporting and analysis by implementing the right software and financial management tools."
    },
    {
      icon: LuAtom,
      title: "Capital Structuring",
      description: "We can help you optimize your financial resources for sustainable growth from initial capital search to wealth management."
    },
    {
      icon: BsAppIndicator,
      title: "Key Performance Indicators",
      description: "We provide insights into your business performance, helping you make informed decisions."
    }
  ])
  const [countsTitle, setCountsTitle] = useState("What we have achieved so far?")
  const [countStatOneValue, setCountStatOneValue] = useState("736")
  const [countStatOneTitle, setCountStatOneTitle] = useState("Clients")
  const [countStatTwoValue, setCountStatTwoValue] = useState("41")
  const [countStatTwoTitle, setCountStatTwoTitle] = useState("Years of Experience")
  const [countStatThreeValue, setCountStatThreeValue] = useState("187")
  const [countStatThreeTitle, setCountStatThreeTitle] = useState("Successful Startups")

  const [bigServices, setBigServices] = useState([
    {
      icon: BsCashStack,
      title: "Accounting and Advisory Services",
      description: "We provide the road-map for your business journey, from startup consulting to accounting setup, business structuring. We then handle your financial records, freeing you to focus on growing your business.",
      iconColor: "text-[#ff689b]"
    },
    {
      icon: IoCalendarOutline,
      title: "Tax Planning and Preparation",
      description: "Stay ahead with strategic tax solutions tailored to your unique business needs.",
      iconColor: "text-[#e9bf06]"
    },
    {
      icon: IoChatboxEllipsesOutline,
      title: "Business Advisory Services",
      description: "If you still have all your plans and ideas locked up inside your head, preparing a strategic plan can help you clarify your company's direction.",
      iconColor: "text-[#3fcdc7]"
    }
  ])
  const [servicesTitle, setServicesTitle] = useState("Our Comprehensive EBS Offerings:")
  const [testimonials, setTestimonials] = useState([
    {
      photo: noPhoto,
      name: 'Sydney Freeman Jr',
      description: 'I truly appreciate the financial services offered to my wife and I by RM White CPA. In particular, we are thankful for the ways they guided us in mapping out our financial plans and goals for the future.They advised us with the same care, concern, and sensitivity as someone would do for their loved ones. I advise their services without reservation.',
    },
    {
      photo: noPhoto,
      name: 'David Sedlacek',
      description: 'Richard White makes the filing of taxes easy. His website is easy to navigate and to use. The instructions are clear and useful. He is an expert when it comes to even the most challenging of tax filings.',
    },
    {
      photo: noPhoto,
      name: 'Eloise Daly-Carter',
      description: 'He is the best. Everyone, call that number. He is honest, trustworthy, thorough and sincere.',
    },
    // Add more testimonial objects here if needed
  ]);

  const [teamTitle, setTeamTitle] = useState("Team")
  const [teamDescription, setTeamDescription] = useState("At R M White CPA, we are committed to guiding you through the incubation period of your business, ensuring that stability and success are not just goals but guarantees.")
  const [teamMembers, setTeamMembers] = useState([
    {
      name: 'Richard White',
      title: 'Managing Partner',
      photoURL: richard_white_photo,
      // memberURL: '',
      // twitterURL: '',
      // facebookURL: '',
      // instagramURL: '',
      // skypeURL: '',
      // linkedinURL: ''
    },
    {
      name: 'Royel White',
      title: 'Accountant',
      photoURL: noPhoto,
      // memberURL: '',
      // twitterURL: '',
      // facebookURL: '',
      // instagramURL: '',
      // skypeURL: '',
      // linkedinURL: ''
    },
    {
      name: 'Laarne Moya',
      title: 'Executive Secretary',
      photoURL: noPhoto,
      // memberURL: '',
      // twitterURL: '',
      // facebookURL: '',
      // instagramURL: '',
      // skypeURL: '',
      // linkedinURL: ''
    }
  ])
  const [contactTitle, setContactTitle] = useState('Contact')
  const [contactDescription, setContactDescription] = useState('If you have any unanswered questions, please let us know.')

  const [emailContactLabel, setEmailContactLabel] = useState('Email:')
  const [emailContact, setEmailContact] = useState("richard@rmwhitecpa.com")

  const [phoneContactLabel, setPhoneContactLabel] = useState('Call:')
  const [phoneContact, setPhoneContact] = useState('+1 (410) 779-9335')

  return (
    <div className='bg-[#fff]'>
      {/* <!-- ======= Hero Section ======= --> */}
      <div className="bg-[#f9f9f9] items-center">
        <div className="flex items-center px-[15%] p-[60px]">
          <div className="w-2/3 space-y-2">
            <p className="m-0 text-[42px] font-[700] text-[#0f394c] uppercase">{heroHeader}</p>
            <h4>{heroDescript}</h4>
            <button type='button' className="font-[500] text-[16px] px-7 py-2 rounded-[3px] text-[#fff] bg-[#F89E34]">
              <a href="#contact" className='text-white hover:text-white'>{heroButtonOneText}</a>
            </button>
            <br></br>
            <button type='button' className="font-[500] text-[16px] px-7 py-2 rounded-[3px] text-[#fff] bg-[#F89E34]">
              {heroButtonTwoText}
            </button>
          </div>
          <div className="w-2/3">
            <img src={hero_img} className="img-fluid" alt="" />
          </div>
        </div>
      </div>

      {/* <!-- ======= About Section ======= --> */}
      <div id='about' className='px-[15%] p-[60px]'>
        <div className='col-auto flex-col items-stretch justify-center py-5 px-5'>
          <p className='text-[28px] font-[700] text-[#0f394c] mb-[15px]'>{aboutHeader}</p>
          <div className='space-y-8'>
            <p>{aboutDescriptionOne}</p>
            <p>{aboutDescriptionTwo}</p>
          </div>

          <div>
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </div>
        </div>
      </div>

      {/* <!-- ======= Counts Section ======= --> */}
      <div className='background-cover pb-[60px] pt-[80px]' style={{ backgroundImage: `url(${counts_bg})`, zIndex: '-1' }}>
        <div className='px-[15%]'>
          <div className='text-[#0f394c] mb-[40px] text-center'>
            <h3 className='text-[36px] font-[700]'>{countsTitle}</h3>
          </div>

          <div className="row counters position-relative flex justify-between">
            <div className="col"></div>
            <div className="col-lg-3 col-6 text-center">
              <p className='text-[44px] font-[700] block text-[#F89E34]'>{countStatOneValue}</p>
              <p className='p-[0px] m-0 mb-[20px] text-[15px] text-[#444444]'>{countStatOneTitle}</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <p className='text-[44px] font-[700] block text-[#F89E34]'>{countStatTwoValue}</p>
              <p className='p-[0px] m-0 mb-[20px] text-[15px] text-[#444444]'>{countStatTwoTitle}</p>
            </div>

            <div className="col-lg-3 col-6 text-center">
              <p className='text-[44px] font-[700] block text-[#F89E34]'>{countStatThreeValue}</p>
              <p className='p-[0px] m-0 mb-[20px] text-[15px] text-[#444444]'>{countStatThreeTitle}</p>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>

      {/* <!-- ======= Clients Section ======= --> */}
      <div className='px-[15%] p-[60px]'>
        <div className='border-t-1 border-t-[#eee] border-l-1 border-l-[#eee] grid grid-cols-4'>

          <div className="col-lg-3 col-md-4 col-6">
            <div className='p-[5px] flex justify-center items-center border-r-1 border-r-[#eee] border-b-1 border-b-[#eee] overflow-hidden bg-[#fff] h-[80px]'>
              <img src={AICPA_logo} className='transition ease-in-out duration-500 hover:filter-none hover:scale-[1.2] h-[50%] grayscale' alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-6">
            <div className='p-[5px] flex justify-center items-center border-r-1 border-r-[#eee] border-b-1 border-b-[#eee] overflow-hidden bg-[#fff] h-[80px]'>
              <img src={MACPA_logo} className='transition ease-in-out duration-500 hover:filter-none hover:scale-[1.2] h-[50%] grayscale' alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-6">
            <div className='p-[5px] flex justify-center items-center border-r-1 border-r-[#eee] border-b-1 border-b-[#eee] overflow-hidden bg-[#fff] h-[80px]'>
              <img src={quickbooks_logo} className='transition ease-in-out duration-500 hover:filter-none hover:scale-[1.2] h-[50%] grayscale' alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-6">
            <div className='p-[5px] flex justify-center items-center border-r-1 border-r-[#eee] border-b-1 border-b-[#eee] overflow-hidden bg-[#fff] h-[80px]'>
              <img src={MSATP_logo} className='transition ease-in-out duration-500 hover:filter-none hover:scale-[1.2] h-[50%] grayscale' alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-6">
            <div className='p-[5px] flex justify-center items-center border-r-1 border-r-[#eee] border-b-1 border-b-[#eee] overflow-hidden bg-[#fff] h-[80px]'>
              <img src={NABA_logo} className='transition ease-in-out duration-500 hover:filter-none hover:scale-[1.2] h-[50%] grayscale' alt="" />
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-6">
            <div className='p-[5px] flex justify-center items-center border-r-1 border-r-[#eee] border-b-1 border-b-[#eee] overflow-hidden bg-[#fff] h-[80px]'>
              <img src={streamlineautomations_logo} className='transition ease-in-out duration-500 hover:filter-none hover:scale-[1.2] h-[50%] grayscale' alt="" />
            </div>
          </div>
        </div>

      </div>

      {/* <!-- ======= Services Section ======= --> */}
      <div className='bg-[#E9D8C6]'>
        <div className='px-[15%] p-[60px]'>

          <div className='text-center pb-[30px]'>
            <h2 className='text-[32px] font-bold uppercase mb-[20px] pb-0 text-[#0f394c]'>{servicesTitle}</h2>
          </div>

          <div className='grid grid-cols-3 space-x-7'>
            {bigServices.map((service, index) => (
              <BigServiceCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
                iconColor={service.iconColor}
              />
            ))}
          </div>
        </div>
      </div>

      {/* <!-- ======= Testimonials Section ======= --> */}
      <div className="relative py-[80px] px-0">
        <div className="absolute inset-0 bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${testimonials_bg})` }}>
          <div className="absolute inset-0 bg-[rgba(11,41,55,0.9)]"></div>
        </div>
        <div className='relative z-10'>
          <div className='px-[15%] py-[20px]'>
            <TestimonialSwiper testimonials={testimonials} />
          </div>
        </div>
      </div>

      {/* <!-- ======= Team Section ======= --> */}
      <div id='team' className='bg-[#E9D8C6]'>
        <div className='px-[15%] p-[60px]'>
          <div className='text-center pb-[30px]'>
            <h2 className='text-[32px] font-bold uppercase mb-[20px] pb-0 text-[#0f394c]'>{teamTitle}</h2>
            <p className='mb-0'>{teamDescription}</p>
          </div>

          <div className='grid grid-cols-3 px-[150px]'>
            {teamMembers.map((member, index) => (
              <TeamCard
                photoURL={member.photoURL}
                name={member.name}
                title={member.title}
                memberPageURL={member.memberURL}
                twitterURL={member.twitterURL}
                facebookURL={member.facebookURL}
                instagramURL={member.instagramURL}
                skypeURL={member.skypeURL}
                linkedinURL={member.linkedinURL}
              />
            ))}
          </div>
        </div>

      </div>

      {/* <!-- ======= Contact Section ======= --> */}
      <div id='contact' className='px-[15%] p-[60px]'>
        <div className='text-center pb-[30px]'>
          <h2 className='text-[32px] font-bold uppercase mb-[20px] pb-0 text-[#0f394c]'>{contactTitle}</h2>
          <p className='mb-0'>{contactDescription}</p>
        </div>

        <div className='flex-row flex'>
          <div className='basis-1/3'>
            <div className='w-full bg-[#fff]'>
              <div className='mt-[40px] text-left items-center flex'>
                <div className="w-[44px] h-[44px] bg-[#017B7B] rounded-full flex justify-center items-center hover:bg-[#F89E34] hover:text-[#fff] text-20 text-[#F89E34] transition-all ease-in-out duration-300">
                  <MdOutlineEmail className="" />
                </div>

                <div>
                  <h4 className='pl-[30px] text-[22px] font-600 mb-[5px] text-[#0f394c]'>{emailContactLabel}</h4>
                  <p className='pl-[30px] mb-0 text-[14px] text-[#2079a1]'>{emailContact}</p>
                </div>
              </div>

              <div className='mt-[40px] text-left items-center flex'>
                <div className="w-[44px] h-[44px] bg-[#017B7B] rounded-full flex justify-center items-center hover:bg-[#F89E34] hover:text-[#fff] text-20 text-[#F89E34] transition-all ease-in-out duration-300">
                  <MdOutlinePhone className="" />
                </div>

                <div>
                  <h4 className='pl-[30px] text-[22px] font-600 mb-[5px] text-[#0f394c]'>{phoneContactLabel}</h4>
                  <p className='pl-[30px] mb-0 text-[14px] text-[#2079a1]'>{phoneContact}</p>
                </div>
              </div>

            </div>
          </div>

          <div className='basis-2/3'>
            <div className='flex flex-row space-x-3'>
              <div className='pb-[8px] basis-1/2'>
                <input type="text" name="name" className='h-[44px] text-[14px] rounded-[4px] p-2 border w-full' id="name" placeholder="Your Name"
                  required />
              </div>
              <div className='pb-[8px] basis-1/2'>
                <input type="email" className='h-[44px] text-[14px] rounded-[4px] p-2 border w-full' name="email" id="email"
                  placeholder="Your Email" required />
              </div>
            </div>
            <div className='pb-[8px]'>
              <input type="text" className='h-[44px] text-[14px] rounded-[4px] p-2 border w-full' name="subject" id="subject" placeholder="Subject"
                required />
            </div>
            <div className='pb-[8px]'>
              <textarea className='px-[12px] py-[10px] text-[14px] rounded-[4px] p-2 border w-full' name="message" rows="5" placeholder="Message"
                required></textarea>
            </div>
            <div>
              <div className={`text-[18px] text-red-500 ${submitError ? 'block' : 'hidden'}`}>Error</div>
              <div className={`text-[18px] text-green-500 ${submitSuccess ? 'block' : 'hidden'}`}>Your message has been sent. Thank you!</div>
            </div>
            <div className='flex justify-center'>
              <Button
                bgColor="#F89E34"
                color="#fff"
                text="Send Message"
                borderRadius="md"
              />
              <div className={`justify-center items-center pl-5 ${isLoading ? "flex" : "hidden"}`}>
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-[rgba(0,0,0,0.2)] border-solid border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                  role="status"
                >
                  <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Home