import React, { useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { IoPerson } from 'react-icons/io5'
import BusinessIntakes from './BusinessIntakes'
import PersonalIntakes from './PersonalIntakes'
import { IoBusiness } from "react-icons/io5";
import { useStateContext } from '../../../contexts/ContextProvider'

const Intakes = () => {
    const [currentTab, setCurrentTab] = useState('Business')
    const defaultTabStyle = 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
    const { currentColor } = useStateContext()

    const tabs = [
        { name: 'Business', href: '#', icon: IoBusiness },
        { name: 'Personal', href: '#', icon: IoPerson },
    ]

    return (
        <div className='mx-2 px-2 md:px-10'>
            {/* Tab Manager */}
            <div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.name}
                                    onClick={() => setCurrentTab(tab.name)}
                                    className={`group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium ${currentTab === tab.name ? '' : defaultTabStyle}`}
                                    style={currentTab === tab.name ? { borderColor: currentColor, color: currentColor } : {}}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    <tab.icon
                                        className={`${currentTab === tab.name ? '' : 'group-hover:text-gray-500'} -ml-0.5 mr-2 h-5 w-5`}
                                        style={currentTab === tab.name ? { color: currentColor } : {}}
                                        aria-hidden="true"
                                    />
                                    <span>{tab.name}</span>
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
            {/* Tab Content */}
            <div className='bg-white dark:bg-secondary-dark-bg rounded-3xl'>
                <div className={`${currentTab === 'Business' ? 'block' : 'hidden'}`}>
                    <BusinessIntakes />
                </div>
                <div className={`${currentTab === 'Personal' ? 'block' : 'hidden'}`}>
                    <PersonalIntakes />
                </div>
            </div>
        </div>
    )
}

export default Intakes
