import React, { useEffect, useState } from 'react'
import { Button, DateField, FormField } from '../../../components'
import YesNoButton from '../../../components/YesNoButton'
import DropdownField from '../../../components/DropdownField'
import { config, getDocument, getPersonalQuestionaire, uploadPersonalIntake } from '../../../lib/appwrite'
import { useNavigate } from 'react-router-dom'

const PersonalIntake = ({ id }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    other_email: '',
    phone: '',
    fax: '',
    street_address: '',
    city: '',
    state: '',
    zipcode: '',
    billing_address_same: '',
    billing_street_address: '',
    billing_city: '',
    billing_state: '',
    billing_zipcode: '',
    occupation: '',
    birth_date: '',
    us_citizen: '',
    self_employed: '',
    marital_status: '',
    dependents: 0
  });

  const [formDataErrors, setFormDataErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    other_email: false,
    phone: false,
    street_address: false,
    city: false,
    state: false,
    zipcode: false,
    billing_address_same: false,
    billing_street_address: false,
    billing_city: false,
    billing_state: false,
    billing_zipcode: false,
    occupation: false,
    birth_date: false,
    us_citizen: false,
    self_employed: false,
    marital_status: false,
    dependents: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        var document = await getDocument(config.personalQuestionaireCollectionId, id)
        document = JSON.parse(document.data)

        // Merge fetched document data with existing formData
        setFormData(prevFormData => ({
          ...prevFormData,
          ...document
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const validateFormData = (data) => {
    const newFormDataErrors = {};

    newFormDataErrors.first_name = data.first_name === '';
    newFormDataErrors.last_name = data.last_name === '';
    newFormDataErrors.email = data.email === '';
    newFormDataErrors.phone = data.phone === '';
    newFormDataErrors.street_address = data.street_address === '';
    newFormDataErrors.city = data.city === '';
    newFormDataErrors.state = data.state === '';
    newFormDataErrors.zipcode = data.zipcode === '';
    newFormDataErrors.billing_address_same = data.billing_address_same === '';
    newFormDataErrors.billing_street_address = data.billing_street_address === '';
    newFormDataErrors.billing_city = data.billing_city === '';
    newFormDataErrors.billing_state = data.billing_state === '';
    newFormDataErrors.billing_zipcode = data.billing_zipcode === '';
    newFormDataErrors.occupation = data.occupation === '';
    newFormDataErrors.birth_date = data.birth_date === '';
    newFormDataErrors.us_citizen = data.us_citizen === '';
    newFormDataErrors.self_employed = data.self_employed === '';
    newFormDataErrors.marital_status = data.marital_status === '';
    newFormDataErrors.dependents = data.dependents === '';

    setFormDataErrors(newFormDataErrors);

    // Check if any errors exist
    if (Object.values(newFormDataErrors).some(error => error)) {
      return false; // Return false if any error is true
    }

    return true;
  };

  const submit = async () => {
    let updatedFormData = { ...formData };

    if (updatedFormData.billing_address_same === 'same') {
      updatedFormData = {
        ...updatedFormData,
        billing_street_address: updatedFormData.street_address,
        billing_city: updatedFormData.city,
        billing_state: updatedFormData.state,
        billing_zipcode: updatedFormData.zipcode
      };
    }

    const isValid = validateFormData(updatedFormData);

    if (!isValid) {
      console.log('Validation errors:', formDataErrors);
      return; // Return early if any error is true
    }

    // Perform the upload
    await uploadPersonalIntake(updatedFormData);

    navigate('/')
  };

  return (
    <div className='px-[15%] p-[60px] space-y-4'>
      <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Intake</h1>

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Primary Contact Information</h2>

      <div className='grid grid-cols-2'>
        <FormField
          title="First Name"
          value={formData.first_name}
          handleChangeText={(e) => setFormData({ ...formData, first_name: e })}
          placeholder=""
          error={formDataErrors.first_name}
          required={true}
          otherStyles="pr-1"
        />
        <FormField
          title="Middle Name"
          value={formData.middle_name}
          handleChangeText={(e) => setFormData({ ...formData, middle_name: e })}
          placeholder=""
          otherStyles="pl-1"
        />

        <FormField
          title="Last Name"
          value={formData.last_name}
          handleChangeText={(e) => setFormData({ ...formData, last_name: e })}
          placeholder=""
          error={formDataErrors.last_name}
          required={true}
          otherStyles="pr-1"
        />
      </div>

      <FormField
        title="Email"
        value={formData.email}
        handleChangeText={(e) => setFormData({ ...formData, email: e })}
        placeholder=""
        error={formDataErrors.email}
        required={true}
      />

      <FormField
        title="Other Email"
        value={formData.other_email}
        handleChangeText={(e) => setFormData({ ...formData, other_email: e })}
        placeholder=""
      />

      <div className='grid grid-cols-2'>
        <FormField
          title="Phone"
          value={formData.phone}
          handleChangeText={(e) => setFormData({ ...formData, phone: e })}
          placeholder=""
          error={formDataErrors.phone}
          required={true}
          otherStyles="pr-1"
        />
        <FormField
          title="Fax"
          value={formData.fax}
          handleChangeText={(e) => setFormData({ ...formData, fax: e })}
          placeholder=""
          otherStyles="pl-1"
        />
      </div>

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Address</h2>

      <FormField
        title="Address"
        value={formData.street_address}
        handleChangeText={(e) => setFormData({ ...formData, street_address: e })}
        placeholder=""
        error={formDataErrors.street_address}
        required={true}
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.city}
          handleChangeText={(e) => setFormData({ ...formData, city: e })}
          placeholder=""
          error={formDataErrors.city}
          required={true}
        />
        <FormField
          title="State"
          value={formData.state}
          handleChangeText={(e) => setFormData({ ...formData, state: e })}
          placeholder=""
          error={formDataErrors.state}
          required={true}
        />
      </div>

      <FormField
        title="Zip"
        value={formData.zipcode}
        handleChangeText={(e) => setFormData({ ...formData, zipcode: e })}
        placeholder=""
        error={formDataErrors.zipcode}
        required={true}
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        value={formData.billing_address_same}
        handleChangeText={(e) => setFormData({ ...formData, billing_address_same: e })}
        error={formDataErrors.billing_address_same}
        required={true}
      />
      {formData.billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, billing_street_address: e })}
            placeholder=""
            error={formDataErrors.billing_street_address}
            required={true}
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.billing_city}
              handleChangeText={(e) => setFormData({ ...formData, billing_city: e })}
              placeholder=""
              error={formDataErrors.billing_city}
              required={true}
            />

            <FormField
              title="State"
              value={formData.billing_state}
              handleChangeText={(e) => setFormData({ ...formData, billing_state: e })}
              placeholder=""
              error={formDataErrors.billing_state}
              required={true}
            />
          </div>
          <FormField
            title="Zip"
            value={formData.billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, billing_zipcode: e })}
            placeholder=""
            error={formDataErrors.billing_zipcode}
            required={true}
          />
        </div>
      )}

      <FormField
        title="Occupation"
        value={formData.occupation}
        handleChangeText={(e) => setFormData({ ...formData, occupation: e })}
        placeholder=""
        error={formDataErrors.occupation}
        required={true}
      />

      <DateField
        title="Birth Date"
        value={formData.birth_date}
        handleChangeText={(e) => setFormData({ ...formData, birth_date: e })}
        placeholder=""
        error={formDataErrors.birth_date}
        required={true}
      />

      <YesNoButton
        title="Are you a U.S. Citizen?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, us_citizen: e })}
        required={true}
        error={formDataErrors.us_citizen}
      />

      <YesNoButton
        title="Self-Employed?"
        option1="yes"
        option2="no"
        handleChangeText={(e) => setFormData({ ...formData, self_employed: e })}
        required={true}
        error={formDataErrors.self_employed}
      />

      <DropdownField
        title="Marital Status"
        selected={formData.marital_status}
        setSelected={(e) => setFormData({ ...formData, marital_status: e })}
        options={[
          "Single",
          "Married",
          "Divorced",
          "Widowed"
        ]}
        handleChangeText={(e) => setFormData({ ...formData, marital_status: e })}
        error={formDataErrors.marital_status}
        required={true}
      />

      <FormField
        title="Number of Dependents"
        value={formData.dependents}
        handleChangeText={(e) => setFormData({ ...formData, dependents: e })}
        placeholder=""
        type="number"
      />

      <div className='flex justify-center items-center'>
        <div onClick={() => submit()}>
          <Button
            bgColor="#F89E34"
            text="Submit"
            borderRadius="[10px]"
            size="md"
          />
        </div>
      </div>

    </div>
  )
}

export default PersonalIntake