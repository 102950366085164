import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormField } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { signIn, getCurrentUser } from '../../lib/appwrite';

const SignIn = (props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [signInError, setSignInError] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const { setIsLoggedIn } = useStateContext();
  const { currentColor, setAccount } = useStateContext();

  const navigate = useNavigate();

  const submit = async () => {
    if (email === "" || password === "") {
      setSignInError("Please fill in all fields");
      return;
    }

    setSubmitting(true);

    try {
      await signIn(email, password);

      const result = await getCurrentUser();
      setAccount(result);
      setIsLoggedIn(true);
      navigate('/');
    } catch (error) {
      setSignInError("Invalid email or password");
      setEmailError(true);
      setPasswordError(true);
      console.error(error);
      setSubmitting(false);
    } finally {
      
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen dark:bg-main-dark-bg">
      <div className="font-bold text-4xl mb-4 dark:text-gray-100">RMWhiteCPA</div>
      <div className='p-5 space-y-4'>
        <FormField
          title="Email"
          value={email}
          handleChangeText={(e) => setEmail(e)}
          placeholder="Enter your email"
          error={emailError}
        />
        <FormField
          title="Password"
          value={password}
          handleChangeText={(e) => setPassword(e)}
          placeholder="Enter your password"
          error={passwordError}
        />
        <p>{signInError}</p>
      </div>

      <a href='/signup'>
        Sign Up
      </a>

      <div
        className='pt-8'
        onClick={submit}
      >
        <Button
          bgColor="#F89E34"
          text="Log In"
          borderRadius="[10px]"
          size="md"
          otherStyles='text-white'
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
}

export default SignIn;
