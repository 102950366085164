import React, { useState, useEffect } from 'react';
import { Header } from '../../../components';
import useAppwrite from '../../../lib/useAppwrite';
import { deleteDocument, config, getAllDocuments } from '../../../lib/appwrite';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../../contexts/ContextProvider';
import { FaEye, FaShareSquare } from 'react-icons/fa';
import { FaTrashCan } from "react-icons/fa6";
import EditableTable from '../../../components/EditableTable';
import ConfirmDelete from '../../../components/ConfirmDelete';
import BusinessIntakeDetails from './BusinessIntakeDetails';

const BusinessIntakes = () => {
  const { currentColor } = useStateContext();
  const [showDetails, setShowDetails] = useState(false)
  const [reset, setReset] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [intakes, setIntakes] = useState([])
  const { data: data, isLoading, refetch } = useAppwrite(
    () => getAllDocuments(config.businessIntakeCollectionId)
  );
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    if (data) {
      var intakeDataArray = []
      intakeDataArray = data.map((intake) => {
        var jsonData = JSON.parse(intake.data)
        jsonData['$id'] = intake.$id;
        return jsonData
      })
      setIntakes(intakeDataArray);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedRowData) {
      setReset(true)
    }
  }, [selectedRowData])

  const handleRowSelect = (data) => {
    setSelectedRowData(data);
  };

  const handleDelete = async () => {
    setConfirmDelete(false)
    const response = await deleteDocument(selectedRowData.$id, config.businessIntakeCollectionId)
    refetch()
    setSelectedRowData(null)
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <div className={`${showDetails ? 'block' : 'hidden'} overflow-scroll`}>
        <BusinessIntakeDetails showDetails={showDetails} setShowDetails={setShowDetails} selectedRowData={selectedRowData} />
      </div>
      <Header category="" title="Business Intakes" />

      <div className='flex flex-1 justify-end items-center my-2'>
        <div className={`${selectedRowData ? 'block' : 'hidden'} flex`}>
          <TooltipComponent content="Show Details" position='TopCenter'>
            <button
              type='button'
              onClick={() => setShowDetails(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaEye className='text-xl' style={{ color: currentColor }} />
            </button>
          </TooltipComponent>
          <TooltipComponent content="Delete" position='TopCenter'>
            <button
              type='button'
              onClick={() => setConfirmDelete(true)}
              style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
              className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
            >
              <FaTrashCan className='text-xl text-red-600' />
            </button>
          </TooltipComponent>
        </div>
      </div>
      <EditableTable
        title=""
        data={intakes}
        setData={setIntakes}
        rowActions={[]}
        keyMapping={{
          'Company Name': 'company_name',
          'Website': 'website',
          'Email': 'pc1_personal_email'
        }}
        onRowClick={handleRowSelect} // Assuming handleRowSelect is a defined function
        reset={reset}
        setReset={setReset}
        selectable={true}
      />
      <ConfirmDelete
        title="Confirm Deletion?"
        text="Are you sure you want to delete the following? This action cannot be undone."
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        visible={confirmDelete}
        setVisible={setConfirmDelete}
        handleConfirm={() => handleDelete()}
      />
    </div>
  );
};

export default BusinessIntakes;