import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import { Pagination } from 'swiper/modules';

const TestimonialSwiper = ({ testimonials }) => {
  return (
    <Swiper pagination={true} modules={[Pagination]} className='mySwiper'>
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className='text-center text-[#fff] italic flex flex-col justify-center items-center mb-10'>
            <img src={testimonial.photo} className='w-[100px] rounded-[50%] border-[6px] border-[rgba(255,255,255,0.15)] mb-4' alt={testimonial.name} />
            <div>
              <h3 className='text-[20px] font-bold mt-0 mb-2 text-[#fff]'>{testimonial.name}</h3>
              <p className='min-w-[992px] mx-auto mb-6 text-[#eee] w-[50%]'>
                <RiDoubleQuotesL className='text-[rgba(255,255,255,0.4)] inline-block left-[-5px] top-[-10px] relative' />
                {testimonial.description}
                <RiDoubleQuotesR className='text-[rgba(255,255,255,0.4)] inline-block right-[-5px] top-[10px] relative' />
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSwiper;
