import React from 'react'

const AccountingAndAdvisoryServices = () => {
  return (
    <div className='bg-[#f9f9f9]'>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Accounting and Advisory Services</h1>
        <br></br>
        <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Empower Your Business with RM White CPA LLC</h2>
        <p>Running a business involves countless responsibilities, from managing finances to driving growth. At RM
          White CPA LLC, we understand that your passion lies in your product or service, not in financial
          complexities. That's why we're here to help you take control of your finances, so you can focus on what
          you do best—running and growing your business.</p>
        <br></br>
        <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Our Services for Business Owners:</h2>
        <ul className="list-disc pl-[40px] pb-2">
          <li><strong>Strategic Growth Planning:</strong> We work with you to develop strategies for sustainable growth.</li>
          <li><strong>Key Performance Metrics Evaluation:</strong> We analyze and interpret key metrics to drive informed decision-making.</li>
          <li><strong>Internal Controls Review:</strong> We assess and enhance your internal controls for efficiency and compliance.</li>
          <li><strong>Budgeting and Cash Flow Management:</strong> Our experts help you manage cash flow effectively and create budgets that align with your goals.</li>
          <li><strong>Business Entity Selection and Restructuring:</strong> We advise on the optimal business structure to maximize benefits and minimize risks.</li>
          <li><strong>Financial Projections and Forecasts:</strong> We provide accurate financial projections to guide your business decisions.</li>
          <li><strong>Debt and Financing Assistance:</strong> We help you navigate debt and financing options to support your business objectives.</li>
          <li><strong>Accounting Systems Setup and Bookkeeping:</strong> Our team sets up efficient accounting systems and handles bookkeeping tasks, ensuring accurate financial records.</li>
        </ul>

        <p>Partner with RM White CPA LLC to streamline your financial management processes and pave the way for your business's success. Let us handle the numbers while you focus on building your legacy.</p>
      </div>
    </div>
  )
}

export default AccountingAndAdvisoryServices