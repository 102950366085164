import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SiShopware } from 'react-icons/si';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

// Icons
import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { IoHomeSharp, IoReceiptOutline } from 'react-icons/io5'
import { CiBoxList } from "react-icons/ci"
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart } from 'react-icons/fi';
import { CiBoxes } from "react-icons/ci";
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
import { MdOutlineSupervisorAccount } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { TiTick } from 'react-icons/ti';
import { GiLouvrePyramid } from 'react-icons/gi';
import { GrLocation } from 'react-icons/gr';
import { CgLogOut } from "react-icons/cg";
import logo from '../assets/header-logo.png'

import { useStateContext } from '../contexts/ContextProvider';
import { signOut } from '../lib/appwrite';
import { BsBox2 } from "react-icons/bs";
import '../css/Home.css'

const WebNavbar = () => {
  return (
    <div className='flex justify-between py-[20px] px-[15%]'>
      <a href="/" className="flex items-center">
        <img src={logo} alt="logo" className="h-10" />
      </a>

      <div class="container d-flex align-items-center" className='flex items-center'>



        <nav id="navbar" class="navbar">
          <ul>
            <li><a class="nav-link scrollto " href="/">Home</a></li>
            <li class="dropdown"><a href="#about"><span>About</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="#about">Mission</a></li>
                <li class="dropdown"><a href="#team"><span>Team</span> <i class="bi bi-chevron-down"></i></a>
                  <ul>
                    <li><a href="/team/richard-white">Richard White</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="dropdown"><a href="#services"><span>Services</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/services/accounting-and-advisory-services">Accounting and Advisory Services</a></li>
                <li><a href="/services/personal-financial-planning">Personal Financial Planning</a></li>
                <li><a href="/services/tax-planning-and-preparation">Tax Planning and Preparation</a></li>
              </ul>
            </li>
            <li class="dropdown"><a href="#"><span>Tax Center</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/tax-center/track-your-refund">Track Your Refund</a></li>
                <li><a href="/tax-center/tax-due-dates">Tax Due Dates</a></li>
                <li><a href="/tax-center/irs-tax-forms-and-publications">IRS Tax Forms and Publications</a></li>
                <li><a href="/tax-center/record-retention-guide">Record Retention Guide</a></li>
                <li><a href="/tax-center/state-tax-forms">State Tax Forms</a></li>
              </ul>
            </li>
            <li class="dropdown"><a href="#"><span>Resources</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li><a href="/resources/blog">Blog</a></li>
                <li><a href="/resources/products">Products</a></li>
                <li><a href="https://rmwhitecpa.clientportal.com/m/login?redirect_url=https%3A%2F%2Frmwhitecpa.clientportal.com%2Fm%2Fclients%2F9667514">Client Portal</a></li>
                {/* <!-- <li><a href="/resources/tax-center">Tax Center</a></li> --> */}
              </ul>
            </li>
            <li><a class="nav-link scrollto" href="/#contact">Contact</a></li>
          </ul>
          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        {/* <!-- .navbar --> */}

      </div>
    </div>

  );
};

export default WebNavbar;
