import React, { useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Toolbar, Edit, Filter, Sort, create } from '@syncfusion/ej2-react-grids';
import { MdOutlineCancel } from 'react-icons/md'
import { updateProduct } from '../../../lib/appwrite';
import { useStateContext } from '../../../contexts/ContextProvider';
import FormInput from '../../../components/FormField';

const PersonalIntakeDetails = ({ showDetails, setShowDetails, selectedRowData }) => {
    const { currentColor } = useStateContext();

    useEffect(() => {
        if (setShowDetails && selectedRowData) {
            
        }
    }, [showDetails]);

    // Form variables
    const [partno, setPartno] = useState('')
    const [description, setDescription] = useState('')

    // Data
    const [jsonData, setJsonData] = useState([]);

    return (
        <div className='bg-half-transparent w-screen fixed nav-item top-0 right-0'>
            <div className='float-right h-screen dark:text-gray-200 bg-white dark:bg-secondary-dark-bg w-[600px]'>
                <div className='flex justify-between items-center p-4 ml-4'>
                    <p className='font-semibold text-xl'>Details</p>
                    <button
                        type='button'
                        onClick={() => setShowDetails(false)}
                        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                        className='text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray'
                    >
                        <MdOutlineCancel />
                    </button>
                </div>

                <div className='flex-col border-t-1 border-color p-4 ml-4 space-y-6'>
                    
                </div>
            </div>
        </div>
    )
}

export default PersonalIntakeDetails