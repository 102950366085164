import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaSkype, FaTwitter } from 'react-icons/fa'

const TeamCard = ({ name, title, photoURL, memberPageURL, twitterURL, facebookURL, instagramURL, skypeURL, linkedinURL }) => {
    return (
        <div className='w-full p-3'>
            <div className='mb-[20px] overflow-hidden rounded-[5px] bg-[#fff] shadow-md'>
                <div className='relative overflow-hidden'>
                    <img src={photoURL} alt="" />
                    <div className='absolute left-0 bottom-[30px] right-0 ease-in-out transition-all duration-300 text-center hover:opacity-[100%] hover:bottom-[15px] opacity-0'>
                        <a className={`hover:bg-[#76c7ed] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[rgba(73,181,231,0.8)] inline-flex items-center justify-center ease-in-out text-[#fff] ${!twitterURL ? 'hidden': ''}`} href={twitterURL}><FaTwitter className='text-[18px]' /></a>
                        <a className={`hover:bg-[#76c7ed] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[rgba(73,181,231,0.8)] inline-flex items-center justify-center ease-in-out text-[#fff] ${!facebookURL ? 'hidden': ''}`} href={facebookURL}><FaFacebook className='text-[18px]' /></a>
                        <a className={`hover:bg-[#76c7ed] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[rgba(73,181,231,0.8)] inline-flex items-center justify-center ease-in-out text-[#fff] ${!instagramURL ? 'hidden': ''}`} href={instagramURL}><FaInstagram className='text-[18px]' /></a>
                        <a className={`hover:bg-[#76c7ed] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[rgba(73,181,231,0.8)] inline-flex items-center justify-center ease-in-out text-[#fff] ${!skypeURL ? 'hidden': ''}`} href={skypeURL}><FaSkype className='text-[18px]' /></a>
                        <a className={`hover:bg-[#76c7ed] transition-all duration-300 mx-[3px] rounded-[50px] w-[36px] h-[36px] bg-[rgba(73,181,231,0.8)] inline-flex items-center justify-center ease-in-out text-[#fff] ${!linkedinURL ? 'hidden': ''}`} href={linkedinURL}><FaLinkedin className='text-[18px]' /></a>
                    </div>
                </div>
                <div className='py-[25px] px[15px] p-5'>
                    <a href={memberPageURL}>
                        <h4 className='font-[700] mb-[5px] text-[18px] text-[#0f394c]'>{name}</h4>
                    </a>
                    <span className='block text-[13px] font-[400] text-[#aaaaaa]'>{title}</span>
                </div>
            </div>
        </div>
    )
}

export default TeamCard