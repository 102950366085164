import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegEdit, FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const EditableTable = ({
    title,
    data,
    setData,
    rowActions,
    addRow,
    keyMapping,
    onRowClick,
    reset,
    setReset,
    selectable,
    rowsPerPage = 10,
}) => {
    const [isAdding, setIsAdding] = useState(false);
    const [newTest, setNewTest] = useState({});
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOrder, setSortOrder] = useState({});
    const [filter, setFilter] = useState({});

    useEffect(() => {
        if (setReset) {
            setSelectedRowIndex(null);
            setReset(false);
        }
    }, [reset]);

    const handleRowClick = (data, index) => {
        if (selectable) {
            if (selectedRowIndex === index) {
                setSelectedRowIndex(null);
                if (onRowClick) {
                    onRowClick(null);
                }
            } else {
                setSelectedRowIndex(index);
                if (onRowClick) {
                    onRowClick(data);
                }
            }
        }
    };

    const handleAddClick = () => {
        setIsAdding(true);
    };

    const handleDeleteClick = (index) => {
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
    };

    const handleSaveClick = () => {
        if (!Array.isArray(data)) {
            setData([]);
        }

        const newData = {};
        Object.keys(newTest).forEach((columnName) => {
            newData[keyMapping[columnName]] = newTest[columnName];
        });

        setData([newData, ...data]);
        setNewTest({});
        setIsAdding(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTest({ ...newTest, [name]: value });
    };

    const handleEditClick = (index) => {
        const updatedData = [...data];
        updatedData[index].isEditing = true;
        setData(updatedData);
    };

    const handleEditChange = (e, index) => {
        const { name, value } = e.target;
        const updatedData = [...data];
        updatedData[index][keyMapping[name]] = value;
        setData(updatedData);
    };

    const handleEditSave = (index) => {
        const updatedData = [...data];
        updatedData[index].isEditing = false;
        setData(updatedData);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (columnName) => {
        const newSortOrder = {};
        newSortOrder[columnName] = sortOrder[columnName] === 'asc' ? 'desc' : 'asc';

        setSortOrder(newSortOrder);

        const sortedData = [...data].sort((a, b) => {
            const key = keyMapping[columnName];
            const aValue = a[key];
            const bValue = b[key];

            // Attempt to parse values as numbers
            const aNumericValue = parseFloat(aValue);
            const bNumericValue = parseFloat(bValue);

            // If both values are numeric, sort them as numbers
            if (!isNaN(aNumericValue) && !isNaN(bNumericValue)) {
                return newSortOrder[columnName] === 'asc' ? aNumericValue - bNumericValue : bNumericValue - aNumericValue;
            }

            // If at least one value is not numeric, sort them as strings
            return newSortOrder[columnName] === 'asc' ? aValue.localeCompare(bValue, 'en', { sensitivity: 'base' }) : bValue.localeCompare(aValue, 'en', { sensitivity: 'base' });
        });

        setData(sortedData);
    };


    const handleFilterChange = (e, columnName) => {
        const value = e.target.value.toLowerCase();
        setFilter({ ...filter, [columnName]: value });
    };

    const filteredData = data.filter(item =>
        Object.keys(filter).every(columnName =>
            item[keyMapping[columnName]].toString().toLowerCase().includes(filter[columnName])
        )
    );

    const startRow = (currentPage - 1) * rowsPerPage;
    const endRow = startRow + rowsPerPage;
    const paginatedData = filteredData.slice(startRow, endRow);

    return (
        <div>
            {addRow && (
                <div className="w-full flex">
                    <p className='font-semibold text-xl'>{title}</p>
                    <button
                        type="button"
                        className="ml-auto block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={isAdding ? handleSaveClick : handleAddClick}
                    >
                        {isAdding ? 'Save' : 'Add'}
                    </button>
                </div>
            )}
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead>
                                <tr>
                                    {Object.keys(keyMapping).map((columnName, index) => (
                                        <th key={index} scope="col" className="py-3.5 pl-4 pr-3 text-sm font-semibold dark:text-white sm:pl-0 text-center">
                                            <button onClick={() => handleSort(columnName)}>
                                                {columnName} {sortOrder[columnName] === 'asc' ? '↑' : sortOrder[columnName] === 'desc' ? '↓' : ''}
                                            </button>
                                            <input
                                                type="text"
                                                value={filter[columnName] || ''}
                                                onChange={(e) => handleFilterChange(e, columnName)}
                                                className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-1 mt-1 rounded"
                                                placeholder={`Filter ${columnName}`}
                                            />
                                        </th>
                                    ))}
                                    {rowActions.length !== 0 && (
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            Actions
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300 dark:divide-gray-800">
                                {isAdding && (
                                    <tr>
                                        {Object.keys(keyMapping).map((columnName, index) => (
                                            <td key={index} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                                <input
                                                    type="text"
                                                    name={columnName}
                                                    value={newTest[columnName] || ''}
                                                    onChange={handleInputChange}
                                                    className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-2 rounded"
                                                    placeholder={`Enter ${columnName}`}
                                                />
                                            </td>
                                        ))}
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <button
                                                type="button"
                                                onClick={handleSaveClick}
                                                className="text-indigo-400 hover:text-indigo-300"
                                            >
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
                                    paginatedData.map((item, index) => (
                                        <tr
                                            key={index}
                                            className={`hover:bg-gray-100 dark:hover:bg-gray-500 cursor-pointer ${selectedRowIndex === startRow + index ? 'bg-gray-200 dark:bg-gray-400' : ''}`} // Adjust for paginated index
                                            onClick={() => handleRowClick && handleRowClick(item, startRow + index)} // Adjust for paginated index
                                        >
                                            {Object.keys(keyMapping).map((columnName, columnIndex) => (
                                                <td key={columnIndex} className="text-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-white sm:pl-0">
                                                    {item.isEditing ? (
                                                        <input
                                                            type="text"
                                                            name={[columnName]}
                                                            value={item[keyMapping[columnName]]}
                                                            onChange={(e) => handleEditChange(e, startRow + index)} // Adjust for paginated index
                                                            className="w-full dark:bg-gray-800 bg-gray-200 dark:text-white text-black p-2 rounded"
                                                            placeholder={`Enter ${columnName}`}
                                                        />
                                                    ) : (
                                                        item[keyMapping[columnName]]
                                                    )}
                                                </td>
                                            ))}
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                {item.isEditing ? (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleEditSave(startRow + index)} // Adjust for paginated index
                                                        className="text-indigo-400 hover:text-indigo-300 mr-2"
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <>
                                                        {rowActions && rowActions.includes('Edit') && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleEditClick(startRow + index)} // Adjust for paginated index
                                                                className="text-indigo-400 hover:text-indigo-300 mr-2"
                                                            >
                                                                <TooltipComponent content="Edit" position='TopCenter'>
                                                                    <FaRegEdit />
                                                                </TooltipComponent>
                                                            </button>
                                                        )}
                                                        {rowActions && rowActions.includes('Delete') && (
                                                            <button
                                                                type="button"
                                                                onClick={() => handleDeleteClick(startRow + index)} // Adjust for paginated index
                                                                className="text-red-400 hover:text-red-300"
                                                            >
                                                                <TooltipComponent content="Delete" position='TopCenter'>
                                                                    <FaTrashCan />
                                                                </TooltipComponent>
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={Object.keys(keyMapping).length + 1} className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className={`justify-between items-center py-2 ${data.length < rowsPerPage ? "hidden" : 'flex'}`}>
                            <button
                                className="px-3 py-1 text-2xl font-medium text-indigo-500 rounded-md hover:text-indigo-400"
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                <TooltipComponent content="Previous Page">
                                    <FaArrowAltCircleLeft className='text-2xl' />
                                </TooltipComponent>
                            </button>
                            <span className="text-sm dark:text-white">
                                Page {currentPage} of {Math.ceil(data.length / rowsPerPage)}
                            </span>
                            <button
                                className="px-3 py-1 font-medium text-indigo-500 rounded-md hover:text-indigo-400"
                                disabled={currentPage === Math.ceil(data.length / rowsPerPage)}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                <TooltipComponent content="Next Page">
                                    <FaArrowAltCircleRight className='text-2xl' />
                                </TooltipComponent>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EditableTable.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
    rowActions: PropTypes.array.isRequired,
    addRow: PropTypes.bool,
    keyMapping: PropTypes.object.isRequired,
    onRowClick: PropTypes.func,
    reset: PropTypes.bool,
    setReset: PropTypes.func,
    selectable: PropTypes.bool,
    rowsPerPage: PropTypes.number,
};

export default EditableTable;