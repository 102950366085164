import React, { useEffect, useState } from 'react'
import { Button, DateField, FormField } from '../../../components'
import YesNoButton from '../../../components/YesNoButton'
import DropdownField from '../../../components/DropdownField'
import { config, getBusinessQuestionaire, getDocument, uploadBusinessIntake } from '../../../lib/appwrite'
import { useNavigate } from 'react-router-dom'

const BusinessIntake = ({ id }) => {
  const navigate = useNavigate()
  const [authorized_states, setAuthorized_States] = useState([])
  const [selected_state, setSelected_State] = useState('')
  const [tempAddState, setTempAddState] = useState('')

  const [formData, setFormData] = useState({
    company_name: '',
    website: '',
    pc1_first_name: '',
    pc1_middle_name: '',
    pc1_last_name: '',
    pc1_personal_email: '',
    pc1_work_email: '',
    pc1_other_email: '',
    pc1_mobile_phone: '',
    pc1_work_phone: '',
    pc1_fax: '',
    pc2_first_name: '',
    pc2_middle_name: '',
    pc2_last_name: '',
    pc2_personal_email: '',
    pc2_work_email: '',
    pc2_other_email: '',
    pc2_mobile_phone: '',
    pc2_work_phone: '',
    pc2_fax: '',
    street_address: '',
    city: '',
    state: '',
    zipcode: '',
    billing_address_same: '',
    billing_street_address: '',
    billing_city: '',
    billing_state: '',
    billing_zipcode: '',
    owner1_first_name: '',
    owner1_middle_name: '',
    owner1_last_name: '',
    owner1_street_address: '',
    owner1_city: '',
    owner1_state: '',
    owner1_zipcode: '',
    owner1_billing_address_same: '',
    owner1_billing_street_address: '',
    owner1_billing_city: '',
    owner1_billing_state: '',
    owner1_billing_zipcode: '',
    owner1_percent_ownership: '',
    owner2_first_name: '',
    owner2_middle_name: '',
    owner2_last_name: '',
    owner2_street_address: '',
    owner2_city: '',
    owner2_state: '',
    owner2_zipcode: '',
    owner2_billing_address_same: '',
    owner2_billing_street_address: '',
    owner2_billing_city: '',
    owner2_billing_state: '',
    owner2_billing_zipcode: '',
    owner2_percent_ownership: '',
    owner3_first_name: '',
    owner3_middle_name: '',
    owner3_last_name: '',
    owner3_street_address: '',
    owner3_city: '',
    owner3_state: '',
    owner3_zipcode: '',
    owner3_billing_address_same: '',
    owner3_billing_street_address: '',
    owner3_billing_city: '',
    owner3_billing_state: '',
    owner3_billing_zipcode: '',
    owner3_percent_ownership: '',
    last_month_of_tax_year: '',
    fein: '',
    state_ein: '',
    tax_registration: '',
    authorized_states: '',
  })
  const [formDataErrors, setFormDataErrors] = useState({
    company_name: false,
    pc1_first_name: false,
    pc1_last_name: false,
    pc1_personal_email: false,
    pc1_mobile_phone: false,
    street_address: false,
    city: false,
    state: false,
    zipcode: false,
    billing_address_same: false,
    billing_street_address: false,
    billing_city: false,
    billing_state: false,
    billing_zipcode: false,
    owner1_first_name: false,
    owner1_last_name: false,
    owner1_street_address: false,
    owner1_city: false,
    owner1_state: false,
    owner1_zipcode: false,
    owner1_billing_address_same: false,
    owner1_billing_street_address: false,
    owner1_billing_city: false,
    owner1_billing_city: false,
    owner1_billing_zipcode: false,
    last_month_of_tax_year: false,
    fein: false,
    state_ein: false,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        var document = await getDocument(config.businessQuestionaireCollectionId, id)
        document = JSON.parse(document.data)

        // Merge fetched document data with existing formData
        setFormData(prevFormData => ({
          ...prevFormData,
          ...document
        }));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      authorized_states
    }));
  }, [authorized_states]);

  const addState = (service) => {
    if (service === '') { return }

    // Check if service is already present in authorized_states
    const index = authorized_states.indexOf(service);

    // If service is present, remove it
    if (index !== -1) {
      const updatedServices = [...authorized_states];
      updatedServices.splice(index, 1);
      setAuthorized_States(updatedServices);
    } else {
      // If service is not present, add it
      setAuthorized_States([...authorized_states, service]);
    }

    setSelected_State('')

    setTempAddState('')
  };

  const validateFormData = (data) => {
    const newFormDataErrors = {};

    newFormDataErrors.company_name = data.company_name === '';
    newFormDataErrors.pc1_first_name = data.pc1_first_name === '';
    newFormDataErrors.pc1_last_name = data.pc1_last_name === '';
    newFormDataErrors.pc1_personal_email = data.pc1_personal_email === '';
    newFormDataErrors.pc1_mobile_phone = data.pc1_mobile_phone === '';
    newFormDataErrors.street_address = data.street_address === '';
    newFormDataErrors.city = data.city === '';
    newFormDataErrors.state = data.state === '';
    newFormDataErrors.zipcode = data.zipcode === '';
    newFormDataErrors.billing_address_same = data.billing_address_same === '';
    newFormDataErrors.billing_street_address = data.billing_street_address === '';
    newFormDataErrors.billing_city = data.billing_city === '';
    newFormDataErrors.billing_state = data.billing_state === '';
    newFormDataErrors.billing_zipcode = data.billing_zipcode === '';
    newFormDataErrors.owner1_first_name = data.owner1_first_name === '';
    newFormDataErrors.owner1_last_name = data.owner1_last_name === '';
    newFormDataErrors.owner1_street_address = data.owner1_street_address === '';
    newFormDataErrors.owner1_city = data.owner1_city === '';
    newFormDataErrors.owner1_state = data.owner1_state === '';
    newFormDataErrors.owner1_zipcode = data.owner1_zipcode === '';
    newFormDataErrors.owner1_billing_address_same = data.owner1_billing_address_same === '';
    newFormDataErrors.owner1_billing_street_address = data.owner1_billing_street_address === '';
    newFormDataErrors.owner1_billing_city = data.owner1_billing_city === '';
    newFormDataErrors.owner1_billing_city = data.owner1_billing_city === '';
    newFormDataErrors.owner1_billing_zipcode = data.owner1_billing_zipcode === '';
    newFormDataErrors.last_month_of_tax_year = data.last_month_of_tax_year === '';
    newFormDataErrors.fein = data.fein === '';
    newFormDataErrors.state_ein = data.state_ein === '';

    setFormDataErrors(newFormDataErrors);

    // Check if any errors exist
    if (Object.values(newFormDataErrors).some(error => error)) {
      return false; // Return false if any error is true
    }

    return true;
  };

  const submit = async () => {
    let updatedFormData = { ...formData };

    if (updatedFormData.billing_address_same === 'same') {
      updatedFormData = {
        ...updatedFormData,
        billing_street_address: updatedFormData.street_address,
        billing_city: updatedFormData.city,
        billing_state: updatedFormData.state,
        billing_zipcode: updatedFormData.zipcode
      };
    }

    if (updatedFormData.owner1_billing_address_same === 'same') {
      updatedFormData = {
        ...updatedFormData,
        owner1_billing_street_address: updatedFormData.owner1_street_address,
        owner1_billing_city: updatedFormData.owner1_city,
        owner1_billing_state: updatedFormData.owner1_state,
        owner1_billing_zipcode: updatedFormData.owner1_zipcode
      };
    }

    if (updatedFormData.owner2_billing_address_same === 'same') {
      updatedFormData = {
        ...updatedFormData,
        owner2_billing_street_address: updatedFormData.owner2_street_address,
        owner2_billing_city: updatedFormData.owner2_city,
        owner2_billing_state: updatedFormData.owner2_state,
        owner2_billing_zipcode: updatedFormData.owner2_zipcode
      };
    }

    if (updatedFormData.owner3_billing_address_same === 'same') {
      updatedFormData = {
        ...updatedFormData,
        owner3_billing_street_address: updatedFormData.owner3_street_address,
        owner3_billing_city: updatedFormData.owner3_city,
        owner3_billing_state: updatedFormData.owner3_state,
        owner3_billing_zipcode: updatedFormData.owner3_zipcode
      };
    }

    const isValid = validateFormData(updatedFormData);

    if (!isValid) {
      console.log('Validation errors:', formDataErrors);
      return; // Return early if any error is true
    }

    // Perform the upload
    await uploadBusinessIntake(updatedFormData);

    navigate('/')
  };

  return (
    <div className='px-[15%] p-[60px] space-y-4'>
      <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Intake</h1>

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Contact Information</h2>

      <FormField
        title="Company Name"
        value={formData.company_name}
        handleChangeText={(e) => setFormData({ ...formData, company_name: e })}
        placeholder=""
        error={formDataErrors.company_name}
        required={true}
      />

      <FormField
        title="Website"
        value={formData.website}
        handleChangeText={(e) => setFormData({ ...formData, website: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Primary Contact 1</h2>

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.pc1_first_name}
          handleChangeText={(e) => setFormData({ ...formData, pc1_first_name: e })}
          placeholder=""
          error={formDataErrors.pc1_first_name}
          required={true}
        />
        <FormField
          title="Middle Name"
          value={formData.pc1_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, pc1_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.pc1_last_name}
        handleChangeText={(e) => setFormData({ ...formData, pc1_last_name: e })}
        placeholder=""
        error={formDataErrors.pc1_last_name}
        required={true}
      />

      <FormField
        title="Personal Email"
        value={formData.pc1_personal_email}
        handleChangeText={(e) => setFormData({ ...formData, pc1_personal_email: e })}
        placeholder=""
        error={formDataErrors.pc1_personal_email}
        required={true}
      />

      <FormField
        title="Work Email"
        value={formData.pc1_work_email}
        handleChangeText={(e) => setFormData({ ...formData, pc1_work_email: e })}
        placeholder=""
      />

      <FormField
        title="Other Email"
        value={formData.pc1_other_email}
        handleChangeText={(e) => setFormData({ ...formData, pc1_other_email: e })}
        placeholder=""
      />

      <FormField
        title="Mobile Phone"
        value={formData.pc1_mobile_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc1_mobile_phone: e })}
        placeholder=""
        error={formDataErrors.pc1_mobile_phone}
        required={true}
      />

      <FormField
        title="Work Phone / Ext"
        value={formData.pc1_work_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc1_work_phone: e })}
        placeholder=""
      />

      <FormField
        title="Fax"
        value={formData.pc1_fax}
        handleChangeText={(e) => setFormData({ ...formData, pc1_fax: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Primary Contact 2</h2>

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.pc2_first_name}
          handleChangeText={(e) => setFormData({ ...formData, pc2_first_name: e })}
          placeholder=""
        />
        <FormField
          title="Middle Name"
          value={formData.pc2_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, pc2_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.pc2_last_name}
        handleChangeText={(e) => setFormData({ ...formData, pc2_last_name: e })}
        placeholder=""
      />

      <FormField
        title="Personal Email"
        value={formData.pc2_personal_email}
        handleChangeText={(e) => setFormData({ ...formData, pc2_personal_email: e })}
        placeholder=""
      />

      <FormField
        title="Work Email"
        value={formData.pc2_work_email}
        handleChangeText={(e) => setFormData({ ...formData, pc2_work_email: e })}
        placeholder=""
      />

      <FormField
        title="Other Email"
        value={formData.pc2_other_email}
        handleChangeText={(e) => setFormData({ ...formData, pc2_other_email: e })}
        placeholder=""
      />

      <FormField
        title="Mobile Phone"
        value={formData.pc2_mobile_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc2_mobile_phone: e })}
        placeholder=""
      />

      <FormField
        title="Work Phone / Ext"
        value={formData.pc2_work_phone}
        handleChangeText={(e) => setFormData({ ...formData, pc2_work_phone: e })}
        placeholder=""
      />

      <FormField
        title="Fax"
        value={formData.pc2_fax}
        handleChangeText={(e) => setFormData({ ...formData, pc2_fax: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Address</h2>

      <FormField
        title="Address"
        value={formData.street_address}
        handleChangeText={(e) => setFormData({ ...formData, street_address: e })}
        placeholder=""
        error={formDataErrors.street_address}
        required={true}
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.city}
          handleChangeText={(e) => setFormData({ ...formData, city: e })}
          placeholder=""
          error={formDataErrors.city}
          required={true}
        />
        <FormField
          title="State"
          value={formData.state}
          handleChangeText={(e) => setFormData({ ...formData, state: e })}
          placeholder=""
          error={formDataErrors.state}
          required={true}
        />
      </div>

      <FormField
        title="Zip"
        value={formData.zipcode}
        handleChangeText={(e) => setFormData({ ...formData, zipcode: e })}
        placeholder=""
        error={formDataErrors.zipcode}
        required={true}
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        handleChangeText={(e) => setFormData({ ...formData, billing_address_same: e })}
        error={formDataErrors.billing_address_same}
        required={true}
      />
      {formData.billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, billing_street_address: e })}
            placeholder=""
            error={formDataErrors.billing_street_address}
            required={true}
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.billing_city}
              handleChangeText={(e) => setFormData({ ...formData, billing_city: e })}
              placeholder=""
              error={formDataErrors.billing_city}
              required={true}
            />

            <FormField
              title="State"
              value={formData.billing_state}
              handleChangeText={(e) => setFormData({ ...formData, billing_state: e })}
              placeholder=""
              error={formDataErrors.billing_state}
              required={true}
            />
          </div>
          <FormField
            title="Zip"
            value={formData.billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, billing_zipcode: e })}
            placeholder=""
            error={formDataErrors.billing_zipcode}
            required={true}
          />
        </div>
      )}

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Owner 1</h2>
      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.owner1_first_name}
          handleChangeText={(e) => setFormData({ ...formData, owner1_first_name: e })}
          placeholder=""
          error={formDataErrors.owner1_first_name}
          required={true}
        />
        <FormField
          title="Middle Name"
          value={formData.owner1_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, owner1_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.owner1_last_name}
        handleChangeText={(e) => setFormData({ ...formData, owner1_last_name: e })}
        placeholder=""
        error={formDataErrors.owner1_last_name}
        required={true}
      />

      <FormField
        title="Address"
        value={formData.owner1_street_address}
        handleChangeText={(e) => setFormData({ ...formData, owner1_street_address: e })}
        placeholder=""
        error={formDataErrors.owner1_street_address}
        required={true}
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.owner1_city}
          handleChangeText={(e) => setFormData({ ...formData, owner1_city: e })}
          placeholder=""
          error={formDataErrors.owner1_city}
          required={true}
        />
        <FormField
          title="State"
          value={formData.owner1_state}
          handleChangeText={(e) => setFormData({ ...formData, owner1_state: e })}
          placeholder=""
          error={formDataErrors.owner1_state}
          required={true}
        />
      </div>

      <FormField
        title="Zip"
        value={formData.owner1_zipcode}
        handleChangeText={(e) => setFormData({ ...formData, owner1_zipcode: e })}
        placeholder=""
        error={formDataErrors.owner1_zipcode}
        required={true}
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        value={formData.owner1_billing_address_same}
        handleChangeText={(e) => setFormData({ ...formData, owner1_billing_address_same: e })}
        error={formDataErrors.owner1_billing_address_same}
        required={true}
      />
      {formData.owner1_billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.owner1_billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, owner1_billing_street_address: e })}
            placeholder=""
            error={formDataErrors.owner1_billing_street_address}
            required={true}
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.owner1_billing_city}
              handleChangeText={(e) => setFormData({ ...formData, owner1_billing_city: e })}
              placeholder=""
              error={formDataErrors.owner1_billing_city}
              required={true}
            />

            <FormField
              title="State"
              value={formData.owner1_billing_state}
              handleChangeText={(e) => setFormData({ ...formData, owner1_billing_state: e })}
              placeholder=""
              error={formDataErrors.owner1_billing_state}
              required={true}
            />
          </div>
          <FormField
            title="Zip"
            value={formData.owner1_billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, owner1_billing_zipcode: e })}
            placeholder=""
            error={formDataErrors.owner1_billing_zipcode}
            required={true}
          />
        </div>
      )}

      <FormField
        title="Percent Ownership"
        value={formData.owner1_percent_ownership}
        handleChangeText={(e) => setFormData({ ...formData, owner1_percent_ownership: e })}
        placeholder=""
        type="number"
        error={formDataErrors.owner1_percent_ownership}
        required={true}
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Owner 2</h2>

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.owner2_first_name}
          handleChangeText={(e) => setFormData({ ...formData, owner2_first_name: e })}
          placeholder=""
        />
        <FormField
          title="Middle Name"
          value={formData.owner2_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, owner2_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.owner2_last_name}
        handleChangeText={(e) => setFormData({ ...formData, owner2_last_name: e })}
        placeholder=""
      />

      <FormField
        title="Address"
        value={formData.owner2_street_address}
        handleChangeText={(e) => setFormData({ ...formData, owner2_street_address: e })}
        placeholder=""
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.owner2_city}
          handleChangeText={(e) => setFormData({ ...formData, owner2_city: e })}
          placeholder=""
        />
        <FormField
          title="State"
          value={formData.owner2_state}
          handleChangeText={(e) => setFormData({ ...formData, owner2_state: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Zip"
        value={formData.owner2_zipcode}
        handleChangeText={(e) => setFormData({ ...formData, owner2_zipcode: e })}
        placeholder=""
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        value={formData.owner2_billing_address_same}
        handleChangeText={(e) => setFormData({ ...formData, owner2_billing_address_same: e })}
      />
      {formData.owner2_billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.owner2_billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, owner2_billing_street_address: e })}
            placeholder=""
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.owner2_billing_city}
              handleChangeText={(e) => setFormData({ ...formData, owner2_billing_city: e })}
              placeholder=""
            />

            <FormField
              title="State"
              value={formData.owner2_billing_state}
              handleChangeText={(e) => setFormData({ ...formData, owner2_billing_state: e })}
              placeholder=""
            />
          </div>
          <FormField
            title="Zip"
            value={formData.owner2_billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, owner2_billing_zipcode: e })}
            placeholder=""
          />
        </div>
      )}

      <FormField
        title="Percent Ownership"
        value={formData.owner2_percent_ownership}
        handleChangeText={(e) => setFormData({ ...formData, owner2_percent_ownership: e })}
        placeholder=""
        type="number"
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Owner 3</h2>

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="First Name"
          value={formData.owner3_first_name}
          handleChangeText={(e) => setFormData({ ...formData, owner3_first_name: e })}
          placeholder=""
        />
        <FormField
          title="Middle Name"
          value={formData.owner3_middle_name}
          handleChangeText={(e) => setFormData({ ...formData, owner3_middle_name: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Last Name"
        value={formData.owner3_last_name}
        handleChangeText={(e) => setFormData({ ...formData, owner3_last_name: e })}
        placeholder=""
      />


      <FormField
        title="Address"
        value={formData.owner3_street_address}
        handleChangeText={(e) => setFormData({ ...formData, owner3_street_address: e })}
        placeholder=""
      />

      <div className='grid grid-cols-2 space-x-2'>
        <FormField
          title="City"
          value={formData.owner3_city}
          handleChangeText={(e) => setFormData({ ...formData, owner3_city: e })}
          placeholder=""
        />
        <FormField
          title="State"
          value={formData.owner3_state}
          handleChangeText={(e) => setFormData({ ...formData, owner3_state: e })}
          placeholder=""
        />
      </div>

      <FormField
        title="Zip"
        value={formData.owner3_zipcode}
        handleChangeText={(e) => setFormData({ ...formData, owner3_zipcode: e })}
        placeholder=""
      />
      <YesNoButton
        title="Billing Address Same"
        option1="same"
        option2="different"
        value={formData.owner3_billing_address_same}
        handleChangeText={(e) => setFormData({ ...formData, owner3_billing_address_same: e })}
      />
      {formData.owner3_billing_address_same === 'different' && (
        <div>
          <FormField
            title="Address"
            value={formData.owner3_billing_street_address}
            handleChangeText={(e) => setFormData({ ...formData, owner3_billing_street_address: e })}
            placeholder=""
          />
          <div className='grid grid-cols-2 space-x-2'>
            <FormField
              title="City"
              value={formData.owner3_billing_city}
              handleChangeText={(e) => setFormData({ ...formData, owner3_billing_city: e })}
              placeholder=""
            />

            <FormField
              title="State"
              value={formData.owner3_billing_state}
              handleChangeText={(e) => setFormData({ ...formData, owner3_billing_state: e })}
              placeholder=""
            />
          </div>
          <FormField
            title="Zip"
            value={formData.owner3_billing_zipcode}
            handleChangeText={(e) => setFormData({ ...formData, owner3_billing_zipcode: e })}
            placeholder=""
          />
        </div>
      )}

      <FormField
        title="Percent Ownership"
        value={formData.owner3_percent_ownership}
        handleChangeText={(e) => setFormData({ ...formData, owner3_percent_ownership: e })}
        placeholder=""
        type="number"
      />

      <FormField
        title="Last Month Of Tax Year"
        value={formData.last_month_of_tax_year}
        handleChangeText={(e) => setFormData({ ...formData, last_month_of_tax_year: e })}
        placeholder=""
        error={formDataErrors.last_month_of_tax_year}
        required={true}
      />

      <FormField
        title="Federal Employer Identification Number (FEIN)"
        value={formData.fein}
        handleChangeText={(e) => setFormData({ ...formData, fein: e })}
        placeholder=""
        error={formDataErrors.fein}
        required={true}
      />

      <FormField
        title="State EIN"
        value={formData.state_ein}
        handleChangeText={(e) => setFormData({ ...formData, state_ein: e })}
        placeholder=""
        error={formDataErrors.state_ein}
        required={true}
      />

      <FormField
        title="Tax Registration"
        value={formData.tax_registration}
        handleChangeText={(e) => setFormData({ ...formData, tax_registration: e })}
        placeholder=""
      />

      <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Sales Tax Information</h2>

      <div>
        <p className="text-base dark:text-gray-100 font-medium">Please List all the States whichin your business collects Sales Tax</p>
        <div className='flex space-x-2'>
          {authorized_states.map((service, index) => (
            <div key={index} className='' onClick={() => addState(service)}>
              <Button
                bgColor="rgba(107,114,128,0.1)"
                text={service}
                borderRadius="[10px]"
                size="md"
              />
            </div>
          ))}
        </div>
        <DropdownField
          title=""
          selected={tempAddState}
          setSelected={setTempAddState}
          options={[
            "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
            "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
            "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
            "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
            "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
            "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
            "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
            "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas",
            "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin",
            "Wyoming"
          ]}
          handleChangeText={(e) => addState(e)}
          value={selected_state}
        />
      </div>

      <div className='flex justify-center items-center'>
        <div onClick={() => submit()}>
          <Button
            bgColor="#F89E34"
            text="Submit"
            borderRadius="[10px]"
            size="md"
          />
        </div>
      </div>

    </div>
  )
}

export default BusinessIntake