import React from 'react'

const IRSTaxFormsAndPublications = () => {
  return (
    <div className='bg-[#f9f9f9]'>
      <div className='px-[15%] p-[60px]'>
        <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>IRS Tax Forms and Publications</h1>
        <table>
          <tr>
            <th>Tax Term</th>
            <th>Description</th>
          </tr>
          <tr>
            <td><a href="https://apps.irs.gov/app/vita/glossary.jsp" target="_blank">Tax Glossary</a></td>
            <td>Tax Glossary</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/f1065sk1.pdf" target="_blank">Schedule K-1 (Form 1065)</a></td>
            <td>Schedule K-1 for Form 1065.</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/f1120ssk.pdf" target="_blank">Schedule K-1 (Form 1120)</a></td>
            <td>Schedule K-1 for Form 1120.</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/f1041sk1.pdf" target="_blank">Schedule K-1 (Form 1041)</a></td>
            <td>Schedule K-1 for Form 1041.</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p1.pdf" target="_blank">Publication 1</a></td>
            <td>Your Rights As a Taxpayer</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p3.pdf" target="_blank">Publication 3</a></td>
            <td>Armed Forces' Tax Guide</td>
          </tr>
          <tr>
            <td><a href="http://www.irs.gov/pub/irs-pdf/p15.pdf" target="_blank">Publication 15</a></td>
            <td>Circular E, Employer's Tax Guide</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p15a.pdf" target="_blank">Publication 15A</a></td>
            <td>Employer's Supplemental Tax Guide</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p15b.pdf" target="_blank">Publication 15B</a></td>
            <td>Employer's Tax Guide to Fringe Benefits</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p17.pdf" target="_blank">Publication 17</a></td>
            <td>Your Federal Income Tax</td>
          </tr>
          <tr>
            <td><a href="http://www.irs.gov/pub/irs-pdf/p51.pdf" target="_blank">Publication 51</a></td>
            <td>Circular A, Agricultural Employer's Tax Guide</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p54.pdf" target="_blank">Publication 54</a></td>
            <td>Tax Guide for U.S. Citizens and Resident Aliens Abroad</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p80.pdf" target="_blank">Publication 80</a></td>
            <td>Circular SS - Federal Tax Guide for Employers in the U.S. Virgin Islands, Guam, American Samoa, and the Commonwealth of the Northern Mariana Islands</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p225.pdf" target="_blank">Publication 225</a></td>
            <td>Farmer's Tax Guide</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p334.pdf" target="_blank">Publication 334</a></td>
            <td>Tax Guide for Small Business</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p463.pdf" target="_blank">Publication 463</a></td>
            <td>Travel, Entertainment, Gift, and Car Expenses</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p501.pdf" target="_blank">Publication 501</a></td>
            <td>Exemptions, Standard Deduction, and Filing Information</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p502.pdf" target="_blank">Publication 502</a></td>
            <td>Medical and Dental Expenses</td>
          </tr>
          <tr>
            <td><a href="http://www.irs.gov/pub/irs-pdf/p503.pdf" target="_blank">Publication 503</a></td>
            <td>Child and Dependent Care Expenses</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p504.pdf" target="_blank">Publication 504</a></td>
            <td>Divorced or Separated Individuals</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p505.pdf" target="_blank">Publication 505</a></td>
            <td>Tax Withholding and Estimated Tax</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p509.pdf" target="_blank">Publication 509</a></td>
            <td>Tax Calendars</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p510.pdf" target="_blank">Publication 510</a></td>
            <td>Excise Taxes (Including Fuel Tax Credits and Refunds)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p514.pdf" target="_blank">Publication 514</a></td>
            <td>Foreign Tax Credit for Individuals</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p515.pdf" target="_blank">Publication 515</a></td>
            <td>Withholding of Tax on Nonresident Aliens and Foreign Corporations</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p516.pdf" target="_blank">Publication 516</a></td>
            <td>U.S. Government Civilian Employees Stationed Abroad</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p517.pdf" target="_blank">Publication 517</a></td>
            <td>Social Security and Other Information for Members of the Clergy & Religious Workers</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p519.pdf" target="_blank">Publication 519</a></td>
            <td>U.S. Tax Guide for Aliens</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p521.pdf" target="_blank">Publication 521</a></td>
            <td>Moving Expenses</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p523.pdf" target="_blank">Publication 523</a></td>
            <td>Selling Your Home</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p524.pdf" target="_blank">Publication 524</a></td>
            <td>Credit for the Elderly or the Disabled</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p525.pdf" target="_blank">Publication 525</a></td>
            <td>Taxable and Nontaxable Income</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p526.pdf" target="_blank">Publication 526</a></td>
            <td>Charitable Contributions</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p527.pdf" target="_blank">Publication 527</a></td>
            <td>Residential Rental Property (Including Rental of Vacation Homes)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p529.pdf" target="_blank">Publication 529</a></td>
            <td>Miscellaneous Deductions</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p530.pdf" target="_blank">Publication 530</a></td>
            <td>Tax Information for First-Time Homeowners</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p531.pdf" target="_blank">Publication 531</a></td>
            <td>Reporting Tip Income</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p535.pdf" target="_blank">Publication 535</a></td>
            <td>Business Expenses</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p536.pdf" target="_blank">Publication 536</a></td>
            <td>Net Operating Losses</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p537.pdf" target="_blank">Publication 537</a></td>
            <td>Installment Sales</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p538.pdf" target="_blank">Publication 538</a></td>
            <td>Accounting Periods and Methods</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p541.pdf" target="_blank">Publication 541</a></td>
            <td>Partnerships</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p542.pdf" target="_blank">Publication 542</a></td>
            <td>Corporations</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p544.pdf" target="_blank">Publication 544</a></td>
            <td>Sales and other Dispositions of Assets</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p547.pdf" target="_blank">Publication 547</a></td>
            <td>Casualties, Disasters, and Thefts</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p550.pdf" target="_blank">Publication 550</a></td>
            <td>Investment Income and Expenses (Including Capital Gains and Losses and Mutual fund Distributions)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p554.pdf" target="_blank">Publication 554</a></td>
            <td>Tax Guide for Seniors</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p555.pdf" target="_blank">Publication 555</a></td>
            <td>Community Property</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p556.pdf" target="_blank">Publication 556</a></td>
            <td>Examination of Returns, Appeal Rights, and Claims for Refund</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p557.pdf" target="_blank">Publication 557</a></td>
            <td>Tax-Exempt Status for Your Organization</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p559.pdf" target="_blank">Publication 559</a></td>
            <td>Survivors, Executors and Administrators</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p560.pdf" target="_blank">Publication 560</a></td>
            <td>Retirement Plans for Small Business</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p570.pdf" target="_blank">Publication 570</a></td>
            <td>Tax Guide for Individuals With Income from U.S. Possessions</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p571.pdf" target="_blank">Publication 571</a></td>
            <td>Tax-Sheltered Annuity Programs for Employees of Public Schools and Certain Tax-Exempt Organizations</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p575.pdf" target="_blank">Publication 575</a></td>
            <td>Pension and Annuity Income</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p583.pdf" target="_blank">Publication 583</a></td>
            <td>Starting a Business and Keeping Records</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p587.pdf" target="_blank">Publication 587</a></td>
            <td>Business Use of Your Home (Including Use by Day-Care Providers)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p590a.pdf" target="_blank">Publication 590-A</a></td>
            <td>Contributions to Individual Retirement Arrangements (IRAs)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p590b.pdf" target="_blank">Publication 590-B</a></td>
            <td>Distributions to Individual Retirement Arrangements (IRAs)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p595.pdf" target="_blank">Publication 595</a></td>
            <td>Capital Construction Fund for Commercial Fishermen</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p596.pdf" target="_blank">Publication 596</a></td>
            <td>Earned Income Credit</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p598.pdf" target="_blank">Publication 598</a></td>
            <td>Tax on Unrelated Business Income of Exempt Organizations</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p721.pdf" target="_blank">Publication 721</a></td>
            <td>Tax Guide to U.S. Civil Service Retirement Benefits</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p901.pdf" target="_blank">Publication 901</a></td>
            <td>U.S. Tax Treaties</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p907.pdf" target="_blank">Publication 907</a></td>
            <td>Tax Highlights for Persons With Disabilities</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p911.pdf" target="_blank">Form 911</a></td>
            <td>Request for Taxpayer Advocate Service Assistance</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p915.pdf" target="_blank">Publication 915</a></td>
            <td>Social Security and Equivalent Railroad Retirement Benefits</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p925.pdf" target="_blank">Publication 925</a></td>
            <td>Passive Activity and At-Risk Rules</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p926.pdf" target="_blank">Publication 926</a></td>
            <td>Household Employers Tax Guide</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p929.pdf" target="_blank">Publication 929</a></td>
            <td>Tax Rules for Children and Dependents</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p936.pdf" target="_blank">Publication 936</a></td>
            <td>Home Mortgage Interest Deduction</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p939.pdf" target="_blank">Publication 939</a></td>
            <td>General Rule for Pensions and Annuities</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p946.pdf" target="_blank">Publication 946</a></td>
            <td>How to Depreciate Property</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p957.pdf" target="_blank">Publication 957</a></td>
            <td>Reporting Back Pay and Special Wage Payments to the Social Security Administration</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p8839.pdf" target="_blank">Form 8839</a></td>
            <td>Qualified Adoption Expenses</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p969.pdf" target="_blank">Publication 969</a></td>
            <td>Health Savings Accounts and Other Tax-Favored Health Plans</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p970.pdf" target="_blank">Publication 970</a></td>
            <td>Tax Benefits for Education</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p1212.pdf" target="_blank">Publication 1212</a></td>
            <td>Guide to Original Issue Discount (OID) Instruments</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p1345.pdf" target="_blank">Publication 1345</a></td>
            <td>Handbook for Authorized IRS e-file Providers of Individual Income Tax Returns</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p1544.pdf" target="_blank">Publication 1544</a></td>
            <td>Reporting Cash Payments of Over $10,000</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p1771.pdf" target="_blank">Publication 1771</a></td>
            <td>Charitable Contributions - Substantiation and Disclosure Requirements</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p1828.pdf" target="_blank">Publication 1828</a></td>
            <td>Tax Guide for Churches and Religious Organizations</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p3079.pdf" target="_blank">Publication 3079</a></td>
            <td>Tax-Exempt Organizations and Gaming</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p3402.pdf" target="_blank">Publication 3402</a></td>
            <td>Taxation of Limited Liability Companies</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p4221pc.pdf" target="_blank">Publication 4221-PC</a></td>
            <td>Compliance Guide For 501(c)(3) Public Charities</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p4221nc.pdf" target="_blank">Publication 4221-NC</a></td>
            <td>Compliance Guide for Tax Exempt Organizations (other than 501(c)(3) Public Charities and Private Foundations)</td>
          </tr>
          <tr>
            <td><a href="https://www.irs.gov/pub/irs-pdf/p4557.pdf" target="_blank">Publication 4557</a></td>
            <td>Safeguarding Taxpayer Data - A Guide for Your Business</td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default IRSTaxFormsAndPublications