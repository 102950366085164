import React, { useEffect, useState } from 'react';
import { Button } from '../../components';
import PersonalIntake from './forms/PersonalIntake';
import BusinessIntake from './forms/BusinessIntake';
import BusinessQuestionaire from './forms/BusinessQuestionaire';
import PersonalQuestionaire from './forms/PersonalQuestionaire'; // Corrected spelling
import { useParams } from 'react-router-dom';

const FormLanding = () => {
    const [formType, setFormType] = useState(null);
    const [documentId, setDocumentId] = useState('');
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            const [type, documentId] = id.split('-');
            setDocumentId(documentId);
            if (type === 'p') {
                setFormType('personal');
            } else if (type === 'b') {
                setFormType('business');
            }
        }
    }, [id]); // Added dependency array

    return (
        <div className='px-[15%] p-[60px]'>
            {formType === 'personal' && (
                <div>
                    {id !== null && id ? (
                        <PersonalIntake id={documentId} />
                    ) : (
                        <PersonalQuestionaire />
                    )}
                </div>
            )}
            {formType === 'business' && (
                <div>
                    {id !== null && id ? (
                        <BusinessIntake id={documentId} />
                    ) : (
                        <BusinessQuestionaire />
                    )}
                </div>
            )}
            {formType === null && (
                <div>
                    <h1 className='m-0 text-[42px] font-[700] text-[#0f394c] uppercase'>Customer Portal</h1>
                    <h2 className='text-[#1c698c] text-[20px] mt-[10px]'>Welcome, are you looking for personal or business help? {id}</h2>
                    <div className='space-x-4 pt-10 flex'>
                        <div onClick={() => setFormType('business')}>
                            <Button
                                bgColor="#F89E34"
                                text="Business"
                                borderRadius="[10px]"
                                size="md"
                            />
                        </div>
                        <div onClick={() => setFormType('personal')}>
                            <Button
                                bgColor="#F89E34"
                                text="Personal"
                                borderRadius="[10px]"
                                size="md"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FormLanding;
