import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from './lib/appwrite';
import * as Pages from './pages/index';
import { Routes, Route } from 'react-router-dom';
import { useStateContext } from './contexts/ContextProvider';
import Loading from './pages/Loading';

const ProtectedRoutes = ({ isAllowed }) => {
    return (
        <Routes>
            <Route path="/" element={<Pages.Dashboard />} />
            <Route path='/access-control' element={<Pages.AccessControl />} />
            <Route path="/admin/dashboard" element={<Pages.Dashboard />} />
            <Route path="/admin/questionaires" element={<Pages.Questionaires />} />
            <Route path="/admin/intakes" element={<Pages.Intakes />} />
            <Route path="/admin/webeditor" element={<Pages.WebEditor />} />
        </Routes>
    );
};

const ProtectedContent = () => {
    const location = useLocation();
    const [isAllowed, setIsAllowed] = useState(false);
    const [loading, setLoading] = useState(true);
    const { fetchAllowedPages } = useStateContext()
    const [allowedPages, setAllowedPages] = useState([])

    useEffect(() => {
        const updatePaths = async () => {
            const paths = await fetchAllowedPages()
            setAllowedPages(paths)
        }

        updatePaths()
    }, [location.pathname])


    useEffect(() => {
        const checkAllowedPage = async () => {
            const currentPath = location.pathname;



            if (allowedPages.includes(currentPath)) {
                return true
            } else {
                return false
            }
        };

        checkAllowedPage().then((allowed) => {
            setIsAllowed(allowed);
            setLoading(false);
        });
    }, [allowedPages]);

    if (loading) {
        return <Loading />; // Or a spinner component
    }

    return isAllowed ? <ProtectedRoutes /> : <div>Access Denied</div>;
};

export default ProtectedContent;

